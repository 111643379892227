$color_1: #ffffff;
$color_2: #5c5a5a;
$color_3: transparent;
$color_4: #8e8d8d;
$color_5: #2443ac;
$color_6: #001935;
$color_7: #93959e;
$color_8: #fff;
$color_9: #0d1927;
$color_10: #2f55d4;
$color_11: #3c4858;
$color_12: #8492a6;
$color_13: #007bff;
$color_14: rgb(36, 67, 172);
$color_15: #ffff;
$color_16: white;
$color_17: #ffc107;
$color_18: #292b2c;
$color_19: #9f9f9f;
$color_20: #db3236;
$color_21: #4d4352;
$color_22: red;
$color_23: #707070;
$color_24: #222;
$color_25: rgba(255, 255, 255, 0.8);
$color_26: rgba(255, 255, 255, 0.9);
$color_27: #454343;
$color_28: black;
$color_29: #081c3c;
$color_30: rgba(0, 0, 0, .7);
$color_31: #000;
$font-family_1: 'Quicksand', sans-serif;
$font-family_2: "Nunito", sans-serif;
$font-family_3: "Lato", sans-serif;
$font-family_4: 'Open Sans';
$font-family_5: 'Noto Sans', sans-serif;
$font-family_6: "Poppins", sans-serif;
$background-color_1: rgba(255, 255, 255, 0.2);
$background-color_2: #2443ac;
$background-color_3: #2f55d4;
$background-color_4: #fff;
$background-color_5: #000;
$background-color_6: #0e0e0e;
$background-color_7: #081c3c;
$background-color_8: #010c29;
$border-color_1: unset;
$border-color_2: #2f55d4;
$border-color_3: #849ae5;

// @import "https://fonts.googleapis.com/css?family=Lato:300,400,700";
.css-1nndiq5-menu{
    display: none !important;
}
.css-1nmdiq5-menu{
    min-width: 200px !important;
}
.category-card {
    i {
        font-size: 50px;
        color: $color_15;
        margin-bottom: 25px;
        display: inline-block;
        line-height: 1;
    }

    text-align: center;
    padding: 30px 12px;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    margin-bottom: 30px;
    border: 1px solid transparent;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: #081c3c;
    min-height: 266px;

    h3 {
        margin-bottom: 10px;
        font-size: 22px;
        color: $color_15;
    }

    &:hover {
        background: #001935;
        opacity: .8;
    }
}
.home-section {
    .banner-section {
        height: 800px;
        background-image: url('./img/slider-landing.webp');
        background-position: center center;
        background-size: cover;
        position: relative;
        z-index: 0;

        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            background: rgba(0, 4, 44, 0.6);
            top: 0;
            left: 0;
            z-index: -1;
        }

        .banner-content {
            p {
                color: $color_1;
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 15px;
                text-align: center !important;
             
            }

            h1 {
                font-size: 65px;
                font-weight: 700;
                color: $color_1;
                max-width: 630px;
                margin: 0 auto 40px;
                text-transform: capitalize;
            }

            .banner-form {
                background: rgb(255, 255, 255);
                padding: 30px;
                border-radius: 10px;
                margin-bottom: 45px;
                box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, .2);
                -webkit-box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, .2);

                .form-group {
                    margin-bottom: 0;
                    line-height: 1;
                    text-align: left;

                    label {
                        font-size: 15px;
                        color: $color_2;
                        font-weight: 600;
                        margin-bottom: 10px;
                    }

                    .form-control {
                        height: 60px;
                        border-radius: 10px;
                        padding: 12px 20px;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;

                        &:focus {
                            border-color: $border-color_1;
                            -webkit-box-shadow: unset;
                            box-shadow: unset;
                            border: 1px solid #2443ac;

                            &::-webkit-input-placeholder {
                                color: $color_3;
                            }

                            &:-ms-input-placeholder {
                                color: $color_3;
                            }

                            &::-ms-input-placeholder {
                                color: $color_3;
                            }

                            &::placeholder {
                                color: $color_3;
                            }
                        }

                        &::-webkit-input-placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            color: $color_4;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                        }

                        &:-ms-input-placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            color: $color_4;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                        }

                        &::-ms-input-placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            color: $color_4;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                        }

                        &::placeholder {
                            font-size: 14px;
                            font-weight: 500;
                            color: $color_4;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                        }
                    }
                }

                .find-btn {
                    background: #2443ac;
                    font-size: 16px;
                    text-transform: uppercase;
                    font-weight: 600;
                    width: 100%;
                    color: $color_1;
                    border-radius: 10px;
                    top: 25px;
                    position: relative;
                    padding: 16px 20px;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    display: block;

                    &:hover {
                        background: #001935;
                    }

                    i {
                        left: 10px;
                        top: 2px;
                        position: relative;
                    }
                }
            }

            .keyword {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    display: inline-block;
                    color: $color_1;
                    font-size: 17px;
                    font-weight: 500;

                    a {
                        color: $color_1;
                        font-size: 17px;
                        font-weight: 500;

                        &:hover {
                            color: $color_5;
                        }
                    }
                }
            }
        }
    }

    .languageSelect {
        &:focus {
            border-color: $border-color_1;
            -webkit-box-shadow: unset;
            box-shadow: unset;
            border: 1px solid #2443ac;
        }

        color: $color_6;
        font-size: 16px;
        font-weight: 600;
    }
    .more_data{
        display: flex;
        justify-content: center;
        align-items: center;
        a{
            background: #2443ac;
            font-size: 16px;
            text-transform: uppercase;
            font-weight: 600;
            width: 100%;
            max-width: 130px;
            color: #ffffff;
            border-radius: 10px;
            top: 25px;
            position: relative;
            padding: 16px 20px;
            transition: 0.5s;
            display: block;
            text-align: center;
         &:hover{
            background: #001935 !important;
         }
        }

    }
    .category-section {
        position: relative;

        .category-block-one {
            position: relative;
            float: left;
            width: 20%;
            margin-bottom: 30px;
        }

        .inner-content {
            position: relative;
            margin: 0px -15px;
        }

        .more-btn {
            position: relative;
            margin-top: 20px;

            .theme-btn-one {
                padding: 17px 48px;
            }
        }

        .pattern-layer {
            position: absolute;
            left: 0px;
            top: 0px;
            right: 0px;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .owl-carousel {
            .owl-stage-outer {
                overflow: visible;

                .owl-item {
                    opacity: 0;
                    transition: all 500ms ease;
                }

                .owl-item.active {
                    opacity: 1;
                }
            }
        }

        .owl-dots {
            margin-top: 17px;
        }
    }

    .category-block-one {
        .inner-box {
            position: relative;
            max-width: 210px;
            width: 100%;
            margin: 0 auto;
            background: #fff;
            padding: 35px 15px;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0px 10px 50px 0px #e4e7ee;
            transition: all 500ms ease;

            &:hover {
                box-shadow: 0px 10px 50px 0px rgba(248, 184, 92, 0.302);

                &:before {
                    height: 100%;
                }

                .icon-box {
                    color: $color_8;
                }

                h5 {
                    color: $color_8 !important;
                }

                span {
                    color: $color_8;
                    background-color: $background-color_1;
                }
            }

            &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 0%;
                left: 0px;
                top: 0px;
                right: 0px;
                transition: all 500ms ease;
                position: absolute;
                content: '';
                width: 100%;
                height: 0%;
                left: 0px;
                top: 0px;
                right: 0px;
                transition: all 500ms ease;
                background-color: $background-color_2;
            }

            .icon-box {
                position: relative;
                display: inline-block;
                font-size: 50px;
                line-height: 50px;
                color: $color_7;
                margin-bottom: 11px;
                transition: all 500ms ease;
            }

            h5 {
                display: block;
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                margin-bottom: 13px;
                position: relative;
            }

            span {
                position: relative;
                display: inline-block;
                font-size: 15px;
                font-weight: 600;
                color: $color_7;
                background: #f2f2f5;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 50%;
                transition: all 500ms ease;
            }

            .shape {
                .shape-1 {
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    width: 61px;
                    height: 77px;
                    background-repeat: no-repeat;
                }

                .shape-2 {
                    position: absolute;
                    left: 0px;
                    bottom: 0px;
                    width: 35px;
                    height: 61px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .owl-nav-none {
        .owl-nav {
            display: none !important;
        }
    }

    .owl-dots-none {
        .owl-dots {
            display: none !important;
        }
    }

    .category-section.alternat-2 {
        padding-top: 215px;
    }

    .owl-carousel {
        .category-block-one {
            float: none;
            width: 100% !important;
        }
    }

    .centred {
        text-align: center;
    }

    .theme-btn-one {
        position: relative;
        display: inline-block;
        overflow: hidden;
        font-size: 16px;
        line-height: 26px;
        font-family: $font-family_1;
        font-weight: 500;
        color: $color_8 !important;
        text-align: center;
        padding: 12px 29px;
        border-radius: 30px;
        box-shadow: 0px 10px 30px 0px rgba(108, 92, 248, 0.4);
        z-index: 1;
        transition: all 500ms ease;
        background-color: $background-color_2;

        &:hover {
            background: #0d1927;
            box-shadow: 0px 10px 30px 0px rgba(13, 25, 39, 0.3);

            &:before {
                width: 100%;
            }
        }

        &:before {
            position: absolute;
            content: '';
            width: 0%;
            height: 100%;
            background: #0d1927;
            border-radius: 30px;
            left: 0px;
            top: 0px;
            z-index: -1;
            transition: all 500ms ease;
        }
    }

    .working {
        color: $color_9;
    }

    h1 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    h2 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    h3 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    h4 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    h5 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    h6 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .h1 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .h2 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .h3 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .h4 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .h5 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .h6 {
        font-family: $font-family_2;
        line-height: 1.5;
        font-weight: 600;
    }

    .text-primary {
        color: $color_10 !important;
    }

    .text-dark {
        color: $color_11 !important;
    }

    .section {
        padding: 100px 0;
        position: relative;
    }

    .section-title {
        position: relative;

        .title.title-line {
            &:before {
                content: "";
                position: absolute;
                height: 2px;
                width: 80px;
                left: 50%;
                top: 60px;
                text-align: center;
                background: #2f55d4;
                margin-left: -42px;
            }

            &:after {
                content: "";
                position: absolute;
                height: 10px;
                width: 10px;
                left: 50%;
                top: 56px;
                z-index: 1;
                text-align: center;
                background: #284bc1;
                margin-left: -7px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

    .para-desc {
        max-width: 600px;
    }

    .text-muted {
        color: $color_12 !important;
    }

    .rounded {
        border-radius: 10px !important;
    }

    .nav-pills {
        .nav-link {
            color: $color_11 !important;
            padding: 0.7rem 2rem;
            font-weight: 700;
        }

        .nav-link.active {
            background-color: $background-color_3;
            color: $color_1 !important;
            border-color: $border-color_2 !important;
            -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
        }
    }

    .job-box {
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;

        .lable {
            width: 200px;
            position: absolute;
            top: -2px;
            left: -86px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            z-index: 1;
            background: #d8dff7;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
        }

        &:hover {
            border-color: $border-color_3 !important;
            -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.15);
            -webkit-transform: translateY(-5px);
            transform: translateY(-5px);

            .lable {
                background: #2f55d4;
            }
        }
    }

    .pagination {
        .page-item.active {
            .page-link {
                background-color: $background-color_3;
                border-color: $border-color_2;
                color: $color_1 !important;
            }
        }
    }

    .pagination.job-pagination {
        .page-link {
            border-radius: 50% !important;
            margin: 0 4px;
            height: 46px;
            width: 45px;
            line-height: 29px;
            text-align: center;
            color: $color_12;
        }
    }

    .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: $color_13;
        background-color: $background-color_4;
        border: 1px solid #dee2e6;
    }

    a {
        text-decoration: none !important;
    }

    .sec-title {
        span {
            position: relative;
            display: block;
            font-size: 17px;
            line-height: 26px;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 13px;
            color: $color_5;
        }

        p {
            max-width: 750px;
            margin: 0 auto;
        }

        margin-bottom: 60px;
    }

    .condidate-item {
        text-align: center;
        max-width: 300px;
        position: relative;
        margin-bottom: 100px;
        border: 4px solid #001935;
        border-radius: 20px;
        box-shadow: 5px 10px #001935;

        .candidate-img {
            border-radius: 10px;

            img {
                border-radius: 10px;
            }
        }

        .candidate-social {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            -webkit-transition: 0.5s;
            transition: 0.5s;

            a {
                i {
                    background: #fff;
                    color: $color_14;
                    width: 35px;
                    height: 35px;
                    border-radius: 30px;
                    line-height: 35px;
                    margin: 0 3px;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;

                    &:hover {
                        background: #001935;
                    }
                }
            }
        }

        .candidate-text {
            background: #001935;
            position: absolute;
            bottom: -98px;
            left: 0;
            right: 0;
            max-width: 95%;
            margin: auto;
            -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
            padding: 20px;
            border-radius: 5px;
            opacity: .8;

            h3 {
                margin-bottom: 15px;

                a {
                    color: $color_8;
                }
            }

            ul {
                padding-left: 0;
                margin-bottom: 0;

                li {
                    list-style: none;
                    font-size: 15px;
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .bottom-text {
                border-top: 1px dashed #e2caca;
                margin-top: 14px;
                padding: 14px 0 0;
                line-height: 1;

                p {
                    margin-bottom: 0;
                    font-size: 16px;
                    float: left;
                    color: $color_8;

                    i {
                        font-size: 18px;
                        top: 3px;
                        position: relative;
                    }
                }

                a {
                    float: right;

                    i {
                        color: $color_8;
                        border: 1px dashed #fff;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50px;

                        &:hover {
                            background: #fd1616;
                            color: $color_1;
                            -webkit-transition: 0.5s;
                            transition: 0.5s;
                        }
                    }
                }
            }
        }

        &:hover {
            .candidate-social {
                bottom: 120px;
            }

            .candidate-text {
                h3 {
                    a {
                        color: $color_16;
                    }
                }
            }
        }
    }

    i.bx.bx-filter-alt {
        color: $color_15;
    }

    i.bx.bx-location-plus {
        color: $color_8;
    }

    li {
        color: $color_8;
    }

    .condidate-slider {
        .owl-dots {
            line-height: 1;
            margin-top: 25px !important;

            .owl-dot {
                span {
                    background: #fdc8bb !important;
                    margin: 0 5px;
                }
            }

            .owl-dot.active {
                span {
                    background: #fd1616 !important;
                }
            }
        }
    }

    .shape-box {
        display: inline-block;
        position: relative;
        z-index: 1;
        max-width: 500px;
        height: 430px;
        margin: 30px 8px 30px;
        box-shadow: 0 6px 30px 0 rgba(0, 0, 0, .12);
        overflow: hidden;
        width: 23.333%;

        >img {
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
        }
    }

    .shape-box_half {
        overflow: hidden;
        text-align: left;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            transform: skewY(53.5deg);
            transform-origin: top left;
            transition: \transform .4s;
            background: #27202d;
            opacity: 0.3;
            z-index: 1;
        }

        figcaption {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 0 30px 30px;
            transition: \transform .4s;
            transform: translateY(100%);
            z-index: 3;

            .show-cont {
                position: absolute;
                bottom: calc(100% + 30px);
                left: 30px;
                right: 30px;
                transition: bottom .4s;
            }
        }

        >.after {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgb(36, 67, 172);
            opacity: 0;
            transition: opacity .4s;
        }

        &:hover {
            &:before {
                transform: skewY(20deg);
            }

            figcaption {
                transform: translateY(0);

                .show-cont {
                    bottom: 100%;
                }
            }

            >.after {
                opacity: 1;
            }
        }
    }

    .bg-black {
        background-color: $background-color_5;
    }

    .card-no {
        font-size: 36px;
        color: $color_17;
        padding: 0;
        margin: 10px 0;
    }

    .card-main-title {
        margin-top: 8px;
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
        color: $color_18;
    }

    .card-content {
        color: $color_19;
        margin-top: 20px;
        line-height: 22px;
        font-size: 15px;
    }

    .read-more-btn {
        border: 2px solid #db3236;
        font-size: 14px;
        cursor: pointer;
        padding: 10px 20px;
        display: inline-block;
        text-transform: uppercase;
        letter-spacing: .08em;
        font-weight: 600;
        position: relative;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        transition: all 0.3s;
        background: #db3236;
        color: $color_8;
        border-radius: 2px;
        margin-top: 25px;
        text-decoration: none;

        &:hover {
            background: transparent;
            color: $color_20;
        }
    }

    .carousel-control-next-icon {
        background-image: url("./img/right.png");
    }

    .carousel-control-prev-icon {
        background-image: url("./img/left.png");
    }

    .testimonial-card {
        padding: 100px 0px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-image: radial-gradient(at 40% 20%, #212529 0px, transparent 50%), radial-gradient(at 80% 0%, #212529 0px, transparent 50%), radial-gradient(at 0% 50%, #212529 0px, transparent 50%), radial-gradient(at 80% 50%, #212529 0px, transparent 50%), radial-gradient(at 0% 100%, #212529 0px, transparent 50%), radial-gradient(at 80% 100%, #212529 0px, transparent 50%), radial-gradient(at 0% 0%, #212529 0px, transparent 50%);
    }

    main {
        width: 800px;

        h1 {
            text-align: center;
            font-size: 40px;
            color: $color_8;
            margin-bottom: 5rem;
        }
    }

    .slide-row {
        display: flex;
        width: 3200px;
        transition: 0.5s;
    }

    .slide-col {
        width: 800px;
        height: 400px;
        position: relative;
    }

    .user-img {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;

        img {
            height: 100%;
            border-radius: 10px;
            width: 320px;
            object-fit: cover;
        }
    }

    .user-text {
        background: rgba(255, 255, 255, 0.7);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(4.5px);
        -webkit-backdrop-filter: blur(4.5px);
        width: 520px;
        height: 270px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 10px;
        color: $color_21;
        padding: 45px;
        z-index: 2;

        p {
            font-size: 18px;
            line-height: 24px;
        }

        h2 {
            margin: 35px 0 5px;
            color: $color_21;
        }
    }

    .indicator {
        display: flex;
        justify-content: center;
        margin-top: 4rem;

        .btn {
            display: inline-block;
            height: 15px;
            width: 15px;
            margin: 4px;
            border-radius: 15px;
            background: #fff;
            cursor: pointer;
            transition: all 0.5s ease-in-out;
        }
    }

    .btn.active {
        width: 45px;
    }

    .testimonial {
        width: 100%;
        overflow: hidden;
    }

    .inner-box {
        svg {
            width: 100%;
            max-width: 34px;
            color: $color_22;
        }
    }

    .location-img {
        position: relative;
        border-radius: 10px;
        margin-bottom: 30px;

        img {
            border-radius: 10px;
            width: 100%;
        }

        &::before {
            position: absolute;
            content: '';
            background: #001935;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: .8;
            border-radius: 10px;
        }

        .location-text {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            text-align: center;
            height: 100%;

            h3 {
                color: $color_1;
                font-size: 25px;
            }

            span {
                color: $color_1;
            }
        }
    }

    .job-style-two {
        background: #081c3c;
        position: relative;
        z-index: 1;

        &::before {
            position: absolute;
            content: '';
            background-image: url('./img/job.png');
            background-position: center center;
            background-size: cover;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            opacity: .02;
            z-index: -1;
        }
    }

    .job-card-two {
        margin-bottom: 30px;
        padding: 40px 30px;
        background: linear-gradient(#081c3c 0%, #21447c 100%);
    }

    .company-logo {
        background: #ffffff;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        border-radius: 100px;
        border: 1px dashed;
        position: relative;
        top: -8px;
    }

    .job-info {
        padding-left: 25px;

        h3 {
            margin-bottom: 10px;
        }

        a {
            color: $color_8;

            &:hover {
                color: $color_22;
            }
        }

        ul {
            padding-left: 0;

            li {
                display: inline-block;
                font-size: 15px;
                padding-right: 10px;

                a {
                    color: $color_22;
                    font-weight: 500;

                    i {
                        top: 2px;
                        position: relative;
                        font-size: 18px;

                        span {
                            background: #fdd6cc;
                            display: inline-block;
                            padding: 5px 10px;
                            color: $color_22;
                            margin-bottom: 10px;
                            border: 1px dashed red;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .theme-btn {
        .default-btn {
            margin-right: 0;
        }
    }

    .white-theme {
        color: $color_15;
    }

    .category-card {
        i {
            font-size: 50px;
            color: $color_15;
            margin-bottom: 25px;
            display: inline-block;
            line-height: 1;
        }

        text-align: center;
        padding: 30px 12px;
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;
        border: 1px solid transparent;
        -webkit-transition: 0.5s;
        transition: 0.5s;
        background: #081c3c;
        min-height: 266px;

        h3 {
            margin-bottom: 10px;
            font-size: 22px;
            color: $color_15;
        }

        &:hover {
            background: #001935;
            opacity: .8;
        }
    }

    .theme-dark {
        .category-card {
            background-color: $background-color_6;
        }
    }

    .category-style-three {
        .category-card {
            background: #fd1616;

            &:hover {
                background: #001935;
                border: 1px solid transparent;
            }
        }
    }

    p {
        font-size: 17px;
        color: $color_23;
        line-height: 1.8;
        // color: $color_15;
        // text-align: center;
    }

    .sectionClass {
        padding: 20px 0px 50px 0px;
        position: relative;
        display: block;
    }

    .fullWidth {
        width: 100% !important;
        display: table;
        float: none;
        padding: 0;
        min-height: 1px;
        height: 100%;
        position: relative;
    }

    .sectiontitle {
        background-position: center;
        margin: 30px 0 0px;
        text-align: center;
        min-height: 20px;

        h2 {
            font-size: 30px;
            color: $color_24;
            margin-bottom: 0px;
            padding-right: 10px;
            padding-left: 10px;
        }
    }

    .headerLine {
        width: 160px;
        height: 2px;
        display: inline-block;
        background: #101F2E;
    }

    .projectFactsWrap {
        display: flex;
        margin-top: 30px;
        flex-direction: row;
        flex-wrap: wrap;

        .item {
            width: 25%;
            height: 100%;
            padding: 50px 0px;
            text-align: center;

            &:nth-child(1) {
                background: rgb(16, 31, 46);
            }

            &:nth-child(2) {
                background: rgb(18, 34, 51);
            }

            &:nth-child(3) {
                background: rgb(21, 38, 56);
            }

            &:nth-child(4) {
                background: rgb(23, 44, 66);
            }

            p.number {
                font-size: 40px;
                padding: 0;
                font-weight: bold;
            }

            p {
                color: $color_25;
                font-size: 18px;
                margin: 0;
                padding: 10px;
                font-family: $font-family_4;
            }

            span {
                width: 60px;
                background: rgba(255, 255, 255, 0.8);
                height: 2px;
                display: block;
                margin: 0 auto;
            }

            i {
                vertical-align: middle;
                font-size: 50px;
                color: $color_25;
            }

            &:hover {
                i {
                    color: $color_16;
                }

                p {
                    color: $color_16;
                }

                span {
                    background: white;
                }
            }
        }
    }

    #projectFacts {
        .fullWidth {
            padding: 0;
        }
    }

    footer {
        z-index: 100;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        bottom: 0;
        left: 0;

        p {
            color: $color_25;
            font-size: 16px;
            opacity: 0;
            font-family: $font-family_4;
            width: 100%;
            word-wrap: break-word;
            line-height: 25px;
            -webkit-transform: translateX(-200px);
            transform: translateX(-200px);
            margin: 0;
            -webkit-transition: all 250ms ease;
            -moz-transition: all 250ms ease;
            transition: all 250ms ease;

            strong {
                color: $color_26;
            }
        }

        .authorWindow {
            a {
                color: $color_16;
                text-decoration: none;
            }
        }
    }

    .about-me-img {
        width: 120px;
        height: 120px;
        left: 10px;
        position: relative;
        border-radius: 100px;
    }

    .authorWindow {
        width: 600px;
        background: #75439a;
        padding: 22px 20px 22px 20px;
        border-radius: 5px;
        overflow: hidden;
    }

    .authorWindowWrapper {
        display: none;
        left: 110px;
        top: 0;
        padding-left: 25px;
        position: absolute;
    }

    .trans {
        opacity: 1;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        transition: all 500ms ease;
    }

    .heading {
        text-align: center;
        color: $color_27;
        font-size: 30px;
        font-weight: 700;
        position: relative;
        margin-bottom: 70px;
        text-transform: uppercase;
        z-index: 999;

        &:after {
            content: ' ';
            position: absolute;
            top: 100%;
            left: 50%;
            height: 40px;
            width: 180px;
            border-radius: 4px;
            transform: translateX(-50%);
            background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
            background-repeat: no-repeat;
            background-position: center;
        }

        span {
            font-size: 18px;
            display: block;
            font-weight: 500;
        }
    }

    .white-heading {
        color: $color_1;

        &:after {
            background: url(https://i.ibb.co/d7tSD1R/heading-line-white.png);
            background-repeat: no-repeat;
            background-position: center;
        }

        span {
            color: $color_1;
        }
    }

    .membership-section {
        background: linear-gradient(#081c3c 0%, #21447c 100%);

        .main-warapper {
            display: none;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 20px;
            margin-top: 20px;
            flex-wrap: wrap;
        }
    }

    .membership-content {
        h2 {
            color: $color_16;
            text-align: center;
            font-size: 38px;
            font-weight: 600;
        }
    }

    .wrapper {
        margin: 10px;
        max-width: 350px;
        min-width: 300px;
        width: 100%;
        background: #fff;
        border-radius: 16px;
        padding: 30px;
        border: 4px solid #001935;
        border-radius: 20px;
        box-shadow: 5px 10px #001935;

        button {
            width: 100%;
            border-radius: 25px;
            border: none;
            outline: none;
            height: 50px;
            font-size: 18px;
            color: $color_8;
            cursor: pointer;
            margin-top: 20px;
            background: linear-gradient(145deg, #081c3c 0%, #21447c 100%);
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(0.98);
            }
        }
    }

    .row {
        .price-details {
            margin: 20px 0;
            text-align: center;
            padding-bottom: 25px;
            border-bottom: 1px solid #e6e6e6;
        }

        .features {
            li {
                display: flex;
                font-size: 15px;
                list-style: none;
                margin-bottom: 10px;
                align-items: center;
                color: $color_28;
            }
        }
    }

    .price-details {
        .price {
            font-size: 65px;
            font-weight: 600;
            position: relative;
            font-family: $font-family_5;
            color: $color_6;

            &::before {
                position: absolute;
                font-weight: 400;
                font-family: $font-family_6;
                content: "$";
                left: -13px;
                top: 17px;
                font-size: 20px;
            }

            &::after {
                position: absolute;
                font-weight: 400;
                font-family: $font-family_6;
                content: "/mon";
                right: -33px;
                bottom: 17px;
                font-size: 13px;
            }
        }

        p {
            font-size: 18px;
            margin-top: 5px;
            color: $color_28;
        }

        h2 {
            font-size: 28px;
            color: $color_29;
        }
    }

    .features {
        li {
            i {
                background: linear-gradient(#081c3c 0%, #21447c 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            span {
                margin-left: 10px;
            }
        }
    }

    .testimonial {
        .row {
            .tabs {
                all: unset;
                margin-right: 50px;
                display: flex;
                flex-direction: column;

                li {
                    all: unset;
                    display: block;
                    position: relative;

                    &:nth-child(1) {
                        align-self: flex-end;

                        &::before {
                            left: 64%;
                            bottom: -50px;
                        }

                        &::after {
                            left: 97%;
                            bottom: -81px;
                        }

                        figure {
                            img {
                                margin-left: auto;
                            }
                        }
                    }

                    &:nth-child(2) {
                        align-self: flex-start;

                        &::before {
                            right: -65px;
                            top: 50%;
                        }

                        &::after {
                            bottom: 101px;
                            border-radius: 50%;
                            right: -120px;
                        }

                        figure {
                            img {
                                margin-right: auto;
                                max-width: 300px;
                                width: 100%;
                                margin-top: -50px;
                            }
                        }
                    }

                    &:nth-child(3) {
                        align-self: flex-end;

                        &::before {
                            right: -10px;
                            top: -66%;
                        }

                        &::after {
                            top: -130px;
                            border-radius: 50%;
                            right: -46px;
                        }

                        figure {
                            img {
                                margin-left: auto;
                                margin-top: -50px;
                            }
                        }

                        &:focus {
                            border: 10px solid red;
                        }
                    }

                    figure {
                        position: relative;

                        img {
                            display: block;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            top: 0;
                            z-index: -1;
                            width: 100%;
                            height: 100%;
                            border: 4px solid #a0abbc;
                            border-radius: 50%;
                            -webkit-transform: scale(1);
                            -ms-transform: scale(1);
                            transform: scale(1);
                            -webkit-transition: 0.3s;
                            -o-transition: 0.3s;
                            transition: 0.3s;
                        }

                        &:hover {
                            &::after {
                                -webkit-transform: scale(1.1);
                                -ms-transform: scale(1.1);
                                transform: scale(1.1);
                            }
                        }
                    }
                }

                li.active {
                    &::before {
                        position: absolute;
                        content: "";
                        width: 50px;
                        height: 50px;
                        background-color: $background-color_7;
                        border-radius: 50%;
                    }

                    &::after {
                        position: absolute;
                        content: "";
                        width: 30px;
                        height: 30px;
                        background-color: $background-color_7;
                        border-radius: 50%;
                    }
                }
            }

            .tabs.carousel-indicators {
                li.active {
                    figure {
                        &::after {
                            -webkit-transform: scale(1.1);
                            -ms-transform: scale(1.1);
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .carousel {
                >h3 {
                    font-size: 20px;
                    line-height: 1.45;
                    color: $color_29;
                    font-weight: 600;
                    margin-bottom: 0;
                }

                h1 {
                    font-size: 40px;
                    line-height: 1.225;
                    margin-top: 23px;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                .carousel-indicators {
                    all: unset;
                    padding-top: 43px;
                    display: flex;
                    list-style: none;

                    li {
                        background: #000;
                        background-clip: padding-box;
                        height: 2px;
                    }
                }

                .carousel-inner {
                    .carousel-item {
                        .quote-wrapper {
                            margin-top: 42px;

                            p {
                                font-size: 18px;
                                line-height: 1.72222;
                                font-weight: 500;
                                color: $color_30;
                            }

                            h3 {
                                color: $color_31;
                                font-weight: 700;
                                margin-top: 37px;
                                font-size: 20px;
                                line-height: 1.45;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    #goToTopButton {
        display: none;
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 99;
        cursor: pointer;
        padding: 5px 15px;
        border: none;
        outline: none;
        background: linear-gradient(#081c3c 0%, #21447c 100%);
        color: $color_8;

        i {
            color: $color_16 !important;
        }
    }

    .swiper-pagination-bullets {
        display: none;
    }

    .swiper {
        width: 100%;
        height: 250px;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
            width: 100%;
            object-fit: cover;
            border-radius: 15px 0px 15px 0px;
        }
    }

    @media (max-width: 786px) {
        .projectFactsWrap {
            .item {
                flex: 0 0 50%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .authorWindow {
            width: 210px;
        }

        .authorWindowWrapper {
            bottom: -170px;
            margin-bottom: 20px;
        }

        footer {
            p {
                font-size: 14px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .testimonial {
            .row {
                .tabs {
                    margin-right: 25px;
                }
            }
        }
    }

    @media (max-width: 767px) {
        p {
            font-size: 16px;
        }

        .pt-100 {
            padding-top: 50px;
        }

        .ptb-100 {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .pb-100 {
            padding-bottom: 50px;
        }

        .pb-70 {
            padding-bottom: 20px;
        }

        .section-title {
            margin-bottom: 30px;

            h2 {
                font-size: 25px;
                margin: 0 auto 10px;
                line-height: 1.4;
            }
        }

        .navbar-area {
            .mobile-nav {
                display: block;
                position: relative;
            }

            .mean-container {
                .mean-bar {
                    background-color: $background-color_8;
                    padding: 0;

                    nav {
                        .navbar-nav {
                            height: 300px;
                            overflow: auto;
                        }
                    }
                }

                a.meanmenu-reveal {
                    padding: 15px 15px 0 0;

                    span {
                        position: relative;
                        top: 8px;
                        margin-top: -5px;
                    }
                }
            }

            .main-nav {
                display: none !important;
            }

            .other-option {
                display: none;
            }
        }
       .banner-section {
            height: 100%;
            padding-top: 150px;
            padding-bottom: 100px;

            &::after {
                display: none;
            }
            .banner-content {
                h1 {
                    font-size: 30px;
                    margin-bottom: 35px;
                }

                .banner-form {
                    padding: 15px;
                    margin-bottom: 35px;

                    .form-group {
                        // margin-bottom: 20px;

                        label {
                            padding-left: 5px;
                        }

                        .form-control {
                            height: 50px;
                        }
                    }

                    .find-btn {
                        top: 0;
                        padding: 10px 20px;
                    }
                }
            }
            .css-13cymwt-control {
                border-width:0px !important;
            }
            .css-13cymwt-control {
             bottom: 7px !important;
            }
        }
    }
    .css-13cymwt-control {
        border-width:0px !important;
    }

}
@media (max-width: 990px){
    .home-section .banner-section .banner-content h1 {
        font-size: 50px !important;
    }
    .home-section .banner-section .banner-content .banner-form {
      padding-bottom:30px !important;
    }
}
@media (max-width: 768px){
    .home-section .banner-section .banner-content h1 {
        font-size: 30px !important;
        width: 100%;
        max-width: 80%;
    }
    
}
@media (max-width: 560px){
img.img-fluid.testimonal-image {
    width: 100%;
    max-width: 164px;
}
.home-section .testimonial .row .tabs li:nth-child(2) figure img {
    margin-right: auto;
    max-width: 215px;
    width: 100%;
    margin-top: -50px;
}
.home-section .testimonial .row .tabs {
    margin-right: 36px;
}
}
@media (max-width: 300px){
    img.img-fluid.testimonal-image {
        width: 100%;
        max-width: 120px;
        display: none;
    }
    .home-section .testimonial .row .tabs li:nth-child(2) figure img {
        margin-right: auto;
        max-width: 215px;
        width: 100%;
        margin-top: -50px;
        display: none;
    }
    }

