.faq-title {
    h2 {
        font-size: 38px;
        font-weight: 600;
        line-height: 1;
        text-transform: capitalize;
    }

    button {
        color: #000 !important;
        font-size: 20px;
        font-weight: 600;
        background-color: rgb(250, 250, 250);
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
    }

    .accordion-button:focus {
        border-color: transparent;
        outline: none !important;
        box-shadow: none !important;
    }

    .accordion-body {
        text-align: left;
        padding: 30px;
        border: 1px solid #ececec;
        border-radius: 5px;
    }

    .accordion-flush {
        border: 1px solid gray;
        border-radius: 10px;
        background-color: rgb(250, 250, 250);
    }
}