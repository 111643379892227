$color_1: var(--placeholder-color);
$color_2: var(--body-color);
$color_3: var(--active-color);
$color_4: #94949f;
$color_5: #081c3c;
$color_6: var(--button-color);
$color_7: var(--subtitle-color);
$color_8: #fff;
$color_9: red;
$color_10: #ffff;
$color_11: #dedede;
$color_12: var(--time-button);
$color_13: var(--level-button);
$color_14: #FFFFFF;
$font-family_1: var(--body-font);
$font-family_2: monospace;
$background-color_1: var(--body-bg-color);
$background-color_2: var(--theme-bg-color);
$background-color_3: var(--header-bg-color);
$background-color_4: var(--active-color);
$background-color_5: transparent;
$background-color_6: #d4e5ff;
$background-color_7: var(--inactive-color);
$background-color_8: var(--active-light-color);
$background-color_9: #575ad8;
$background-color_10: var(--placeholder-color);
$background-color_11: #fff;
$background-color_12: #DDD;
$border-color_1: var(--active-color);
$border-color_2: #fff;

:root {
    --body-bg-color: #e5ecef;
    --theme-bg-color: #fafafb;
    --body-font: "Poppins", sans-serif;
    --body-color: #2f2f33;
    --active-color: #081c3c;
    --active-light-color: #e1ebfb;
    --header-bg-color: #fff;
    --search-border-color: #efefef;
    --border-color: #d8d8d8;
    --alert-bg-color: #e8f2ff;
    --subtitle-color: #83838e;
    --inactive-color: #f0f0f0;
    --placeholder-color: #9b9ba5;
    --time-button: #fc5757;
    --level-button: #5052d5;
    --button-color: #fff;
}

@keyframes slideY {
    0% {
        opacity: 0;
        transform: translateY(200px);
    }
}

@keyframes slide {
    0% {
        opacity: 0;
        transform: translateX(300px);
    }
}

.job-section {
    .btn-primary {
        background: #2443ac !important;
        outline: none !important;
        border: none !important;
    }

    &::placeholder {
        color: $color_1;
    }

    img {
        width: 100px;
        height: 100px;
        border-radius: 100%;
        border: 1px dashed white;
    }

    html {
        box-sizing: border-box;
        -webkit-font-smoothing: antialiased;
    }

    body {
        background-color: $background-color_1;
        font-family: $font-family_1;
        font-size: 15px;
        color: $color_2;
    }

    .job {
        display: flex;
        flex-direction: column;
        max-width: 1400px;
        margin: 0 auto;
        overflow: hidden;
        background-color: $background-color_2;
    }

    .logo {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        cursor: pointer;

        svg {
            width: 24px;
            margin-right: 12px;
        }
    }

    .header {
        display: flex;
        align-items: center;
        transition: box-shadow 0.3s;
        flex-shrink: 0;
        padding: 0 40px;
        white-space: nowrap;
        background-color: $background-color_3;
        height: 60px;
        width: 100%;
        font-size: 14px;
        justify-content: space-between;
    }

    .header-menu {
        a {
            text-decoration: none;
            color: $color_2;
            font-weight: 500;

            &:hover {
                color: $color_3;
            }

            &:not(:first-child) {
                margin-left: 30px;
            }
        }

        a.active {
            color: $color_3;
        }
    }

    .user-settings {
        display: flex;
        align-items: center;
        font-weight: 500;

        svg {
            width: 20px;
            color: $color_4;
        }
    }

    .user-menu {
        position: relative;
        margin-right: 8px;
        padding-right: 8px;
        border-right: 2px solid #d6d6db;

        &:before {
            position: absolute;
            content: "";
            width: 7px;
            height: 7px;
            border-radius: 50%;
            border: 2px solid var(#fff);
            right: 6px;
            top: -1px;
            background-color: $background-color_4;
        }
    }

    .user-profile {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
        margin-right: 10px;
    }

    .job-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        scroll-behavior: smooth;
        padding: 30px 40px;
        overflow: auto;
        padding-top: 20px;
    }

    .search-menu {
        height: 56px;
        white-space: nowrap;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        background-color: $background-color_3;
        border-radius: 8px;
        width: 100%;
        padding-left: 20px;
        column-gap: 15px;

        div {
            &:not(:last-of-type) {
                border-right: 1px solid var(#efefef);
            }
        }
    }

    .search-bar {
        // height: 55px;
        width: 100%;
        position: relative;

        input {
            width: 100%;
            height: 100%;
            display: block;
            background-color: $background-color_5;
            border: none;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-size: 14px;
            background-position: 0 50%;
            padding: 0 25px 0 305px;
        }
    }

    .search-location {
        display: flex;
        align-items: center;
        width: 50%;
        font-size: 14px;
        font-weight: 500;
        padding: 0 25px;
        height: 100%;

        input {
            width: 100%;
            height: 100%;
            display: block;
            background-color: $background-color_5;
            border: none;
        }

        svg {
            margin-right: 8px;
            width: 18px;
            color: $color_5;
            flex-shrink: 0;
        }
    }

    .search-job {
        display: flex;
        align-items: center;
        width: 50%;
        font-size: 14px;
        font-weight: 500;
        padding: 0 25px;
        height: 100%;

        input {
            width: 100%;
            height: 100%;
            display: block;
            background-color: $background-color_5;
            border: none;
        }

        svg {
            margin-right: 8px;
            width: 18px;
            color: $color_5;
            flex-shrink: 0;
        }
    }

    .search-salary {
        display: flex;
        align-items: center;
        width: 50%;
        font-size: 14px;
        font-weight: 500;
        padding: 0 25px;
        height: 100%;

        input {
            width: 100%;
            height: 100%;
            display: block;
            background-color: $background-color_5;
            border: none;
        }

        svg {
            margin-right: 8px;
            width: 18px;
            color: $color_5;
            flex-shrink: 0;
        }
    }

    .search-button {
        background: linear-gradient(#081c3c 0%, #21447c 100%);
        height: 55px;
        border: none;
        font-weight: 600;
        font-size: 14px;
        padding: 0 15px;
        border-radius: 0 8px 8px 0;
        color: $color_6;
        cursor: pointer;
        margin-left: auto;
    }

    .search.item {
        position: absolute;
        top: 10px;
        left: 25px;
        font-size: 13px;
        color: $color_3;
        border: 1px solid var(#efefef);
        padding: 8px 10px;
        border-radius: 8px;
        display: flex;
        align-items: center;

        svg {
            width: 12px;
            margin-left: 5px;
        }

        &:last-child {
            left: 185px;
        }
    }

    .main-container {
        display: flex;
        flex-grow: 1;
        padding-top: 30px;
    }

    .search-type {
        width: 270px;
        display: flex;
        flex-direction: column;
        height: 100%;
        flex-shrink: 0;
    }

    .alert {
        background-color: $background-color_6;
        padding: 24px 18px;
        border-radius: 8px;

        input {
            width: 100%;
            padding: 10px;
            display: block;
            border-radius: 6px;
            background-color: $background-color_3;
            border: none;
            font-size: 13px;
        }
    }

    .alert-title {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 8px;
    }

    .alert-subtitle {
        font-size: 13px;
        color: $color_7;
        line-height: 1.6em;
        margin-bottom: 20px;
    }

    .search-buttons {
        border: none;
        color: $color_6;
        background: linear-gradient(#081c3c 0%, #21447c 100%);
        padding: 8px 10px;
        border-radius: 6px;
        font-size: 13px;
        font-weight: 600;
        margin-top: 14px;
    }

    .job-time {
        padding-top: 20px;
    }

    .job-time-title {
        font-size: 14px;
        font-weight: 500;
    }

    .type-container {
        display: flex;
        align-items: center;
        color: $color_7;
        font-size: 13px;
        justify-content: space-between;

        label {
            margin-left: 2px;
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        + {
            .type-container {
                margin-top: 10px;
            }
        }
    }

    .job-number {
        margin-left: auto;
        background-color: $background-color_7;
        color: $color_7;
        font-size: 10px;
        font-weight: 500;
        padding: 5px;
        border-radius: 4px;
    }

    .job-style {
        display: none;

        + {
            label {
                &:before {
                    content: "";
                    margin-right: 10px;
                    width: 16px;
                    height: 16px;
                    border: 1px solid var(--subtitle-color);
                    border-radius: 4px;
                    cursor: pointer;
                }
            }
        }

        &:checked {
            + {
                label {
                    &:before {
                        background: #081c3c;
                        border-color: $border-color_1;
                        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='3' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpath d='M20 6L9 17l-5-5'/%3e%3c/svg%3e");
                        background-position: 50%;
                        background-size: 14px;
                        background-repeat: no-repeat;
                    }

                    + {
                        span {
                            background-color: $background-color_8;
                            color: $color_5;
                        }
                    }
                }
            }
        }
    }

    .searched-jobs {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-left: 40px;
    }

    .searched-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        animation: slideY 0.6s both;

        .form-select {
            width: 18% !important;
            border: none;
            border-radius: 8px;
            height: 44px;
        }

        .form-select:focus {
            border-color: #86b7fe;
            outline: 0;
            box-shadow: none !important;
        }

        .form-select:hover {
            background-color: #f8f9fa;
            /* Change this to the desired hover color */
        }
    }

    .job-card-two {
        margin-bottom: 30px;
        padding: 25px 30px;
        background: linear-gradient(#1e3c72 100%, #2a5298 0%);
        position: relative;
    }

    .company-logo {
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        border-radius: 100px;
        // position: relative;
        // top: -8px;
    }

    .job-info {
        // padding-left: 40px;

        h3 {
            margin-bottom: 10px;
        }

        p {
            color: $color_8;
            font-size: 22px !important;
            margin-bottom: 0px;
        }

        ul {
            padding-left: 0;
            margin-bottom: 10px !important;

            li {
                display: inline-block;
                font-size: 15px;
                padding-right: 10px;

                a {
                    color: $color_9;
                    font-weight: 500;

                    i {
                        top: 2px;
                        position: relative;
                        font-size: 18px;

                        span {
                            background: #fdd6cc;
                            display: inline-block;
                            padding: 5px 10px;
                            color: $color_9;
                            margin-bottom: 10px;
                            border: 1px dashed red;
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    li {
        color: $color_8;
    }

    .white-theme {
        color: $color_10;
    }

    .search-box {
        &:focus-visible {
            outline: none !important;
        }
    }

    .searched-show {
        font-size: 19px;
        font-weight: 600;
    }

    .searched-sort {
        font-size: 14px;
        color: $color_7;

        .post-time {
            font-weight: 600;
            color: $color_7;
        }

        .menu-icon {
            font-size: 9px;
            color: $color_1;
            margin-left: 6px;
        }
    }

    .job-cards {
        padding-top: 20px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 25px;
        animation: slideY 0.6s both;
    }

    .job-card {
        padding: 20px 16px;
        background-color: $background-color_3;
        border-radius: 8px;
        cursor: auto !important;
        transition: 0.2s;

        &:hover {
            transform: scale(1.02);
        }

        svg {
            width: 46px;
            padding: 10px;
            border-radius: 8px;
        }
    }

    .job-card-title {
        font-weight: 600;
        margin-top: 16px;
        font-size: 14px;
    }

    .job-card-subtitle {
        color: $color_7;
        font-size: 13px;
        margin-top: 14px;
        line-height: 1.6em;
    }

    .job-card-header {
        display: flex;
        align-items: flex-start;
    }

    .overview-card {
        &:hover {
            background: #2b2ecf;
            transition: none;
            transform: scale(1);

            svg {
                box-shadow: none;
            }

            .job-overview-buttons {
                .search-buttons.time-button {
                    background-color: $background-color_9;
                    color: $color_8;
                }

                .search-buttons.level-button {
                    background-color: $background-color_9;
                    color: $color_8;
                }
            }

            .job-card-title {
                color: $color_8;
            }

            .job-stat {
                color: $color_8;
            }

            .job-card-subtitle {
                color: $color_11;
            }

            .job-day {
                color: $color_11;
            }

            .overview-wrapper {
                .heart {
                    color: $color_8;
                    border-color: $border-color_2;

                    &:hover {
                        fill: red;
                        stroke: red;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }

    .detail-button {
        background-color: $background-color_8;
        color: $color_3;
        font-size: 11px;
        font-weight: 500;
        padding: 6px 8px;
        border-radius: 4px;

        + {
            .detail-button {
                margin-left: 4px;
            }
        }
    }

    .job-card-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 4px;
    }

    .card-buttons {
        padding: 10px;
        width: 100%;
        font-size: 12px;
        cursor: pointer;
        margin-right: 12px;
    }

    .card-buttons-msg {
        padding: 10px;
        width: 100%;
        font-size: 12px;
        cursor: pointer;
        background-color: $background-color_7;
        color: $color_7;
    }

    .menu-dot {
        background-color: $background-color_10;
        box-shadow: -6px 0 0 0 var(#9b9ba5), 6px 0 0 0 var(#9b9ba5);
        width: 4px;
        height: 4px;
        border: 0;
        padding: 0;
        border-radius: 50%;
        margin-left: auto;
        margin-right: 8px;
    }

    .header-shadow {
        box-shadow: 0 4px 20px rgba(88, 99, 148, 0.17);
        z-index: 1;
    }

    .job-overview {
        display: flex;
        flex-grow: 1;
        display: none;
        animation: slide 0.6s both;

        .overview-wrapper {
            display: flex;
            align-items: center;

            svg {
                &:first-child {
                    width: 42px;
                    margin-right: 10px;
                }
            }

            .heart {
                background: none;
                box-shadow: none;
                width: 24px;
                padding: 4px;
                color: $color_7;
                border: 1px solid var(#d8d8d8);
                margin-left: auto;
                margin-bottom: auto;
            }
        }
    }

    .job-overview-cards {
        display: flex;
        flex-direction: column;
        width: 330px;
        height: 100%;
        flex-shrink: 0;
    }

    .job-overview-card {
        + {
            .job-overview-card {
                margin-top: 20px;
            }
        }
    }

    .job-overview-buttons {
        display: flex;
        align-items: center;
        margin-top: 12px;

        .search-buttons {
            background-color: $background-color_7;
            font-size: 11px;
            padding: 6px 8px;
            margin-top: 0;
            font-weight: 500;
        }

        .search-buttons.time-button {
            color: $color_12;
            margin-right: 8px;
        }

        .search-buttons.level-button {
            color: $color_13;
        }

        .job-stat {
            color: $color_3;
            font-size: 12px;
            font-weight: 500;
            margin-left: auto;
        }

        .job-day {
            color: $color_7;
            font-size: 12px;
            margin-left: 8px;
            font-weight: 500;
        }
    }

    .overview-detail {
        .job-card-title {
            margin-top: 4px;
        }

        .job-card-subtitle {
            margin-top: 4px;
            font-size: 12px;
            font-weight: 500;
        }
    }

    .job-explain {
        background-color: $background-color_3;
        margin-left: 40px;
        border-radius: 0 0 8px 8px;
    }

    .job-bg {
        border-radius: 8px 8px 0 0;
        object-fit: cover;
        width: 100%;
        height: 180px;
        transition: 0.3s;
        position: relative;
    }

    .job-logos {
        margin-top: -30px;
        position: relative;
        margin-bottom: -36px;
        padding: 0 20px;

        svg {
            width: 66px;
            padding: 12px;
            background-color: $background-color_11;
            border-radius: 10px;
            border: 4px solid var(#fff);
        }
    }

    .job-title-wrapper {
        display: flex;
        align-items: center;

        .job-card-title {
            font-size: 20px;
            margin-top: 0;
            font-weight: 600;
        }
    }

    .job-action {
        display: flex;
        align-items: center;
        margin-left: auto;

        svg {
            width: 32px;
            border: 1px solid var(#d8d8d8);
            color: $color_7;
            border-radius: 8px;
            padding: 6px;

            + {
                svg {
                    margin-left: 12px;
                }
            }
        }
    }

    .job-explain-content {
        padding: 50px 25px 30px;
    }

    .job-subtitle-wrapper {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .posted {
            margin-left: auto;
            color: $color_7;
            font-size: 12px;
            font-weight: 500;
        }

        .company-name {
            color: $color_3;
            font-weight: 600;
            font-size: 14px;
        }

        .comp-location {
            color: $color_7;
            font-size: 12px;
            font-weight: 500;
            position: relative;
            margin-left: 10px;

            &:before {
                content: "";
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $background-color_10;
                top: 49%;
                left: -8px;
                position: absolute;
            }
        }

        .app-number {
            color: $color_2;
            position: relative;
            margin-left: 12px;

            &:before {
                content: "";
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $background-color_10;
                top: 50%;
                left: -7px;
                position: absolute;
            }
        }
    }

    .explain-bar {
        margin-top: 20px;
        border: 1px solid var(#d8d8d8);
        border-radius: 8px;
        display: flex;
        height: 66px;
        padding: 0 16px;
        align-items: center;
        justify-content: space-between;
    }

    .explain-title {
        color: $color_7;
        font-size: 12px;
        line-height: 40px;
        white-space: nowrap;
    }

    .explain-subtitle {
        font-size: 13px;
        font-weight: 500;
        margin-top: -2px;
        white-space: nowrap;
    }

    .explain-contents {
        height: 66px;

        + {
            .explain-contents {
                border-left: 1px solid var(#d8d8d8);
                padding-left: 16px;
            }
        }
    }

    .overview-text {
        margin-top: 30px;
    }

    .overview-text-header {
        font-weight: 600;
        margin-bottom: 25px;
    }

    .overview-text-subheader {
        font-size: 13px;
        line-height: 2em;
    }

    .overview-text-item {
        font-size: 13px;
        position: relative;
        display: flex;

        + {
            .overview-text-item {
                margin-top: 20px;
            }
        }

        &:before {
            content: "";
            border: 2px solid #081c3c;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            margin-right: 8px;
            flex-shrink: 0;
        }
    }

    .detail-page {
        .job-overview {
            display: flex;
        }

        .job-cards {
            display: none;
        }

        .searched-bar {
            display: none;
        }
    }
    // .cover-ribbon {
    //     height: 115px;
    //     width: 115px;
    //     position: absolute;
    //     right: -8px;
    //     top: -8px;
    //     overflow: hidden;
    // }

    // .cover-ribbon .cover-ribbon-inside {
    //     background: #EA4335;
    //     color: #FFF;
    //     transform: rotate(45deg);
    //     position: absolute;
    //     right: -35px;
    //     top: 15px;
    //     padding: 10px;
    //     min-width: 127px;
    //     text-align: center;
    // }



.card__pricing {
    position: absolute;
    background: #58719f;
    width: 79px;
    right: 0;
    top: -1rem;
    text-align: center;
    color: #fff;
    padding: 10px 0 10px 0;
}







.card__pricing::after, 
.card__pricing::before {
  content: '';
  position: absolute;
}

.card__pricing::after {
  width: 100%;
  height: 14px;
  background-color:#58719f;
  left: 0;
  bottom: 0;
  clip-path: polygon(0 100%, 50% 0, 100% 100%);
}

.card__pricing::before {
  width: 14px;
  height: 16px;
  background-color:#58719f !important;
  top: 0;
  left: -14px;
  clip-path: polygon(0 100%, 100% 0, 100% 100%);
}

.card__list {
  row-gap: .5rem;
  margin-bottom: var(--mb-2);
}

.card__list-item {
  display: flex;
  align-items: center;
}


    .center {
        text-align: center;
        font-family: $font-family_2;
    }

    .find-search-image {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;

        .find-search-img {
            width: 100% !important;
            height: auto !important;
            border-radius: 0px !important;
            max-width: 600px;
        }
    }


    @media screen and (max-width: 1212px) {
        .job-cards {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 1198px) {

        .job-info {
            padding-left: 10px;
            padding-top: 10px;
        }
    }

    @media screen and (max-width: 930px) {
        .job-cards {
            grid-template-columns: repeat(1, 1fr);
        }

        .search-job {
            display: none;
        }

        .search-salary {
            display: none;
        }

        .search-bar {
            width: auto;
        }
    }

    @media screen and (max-width: 1300px) {
        .detail-page {
            .search-type {
                display: none;
            }

            .searched-jobs {
                padding-left: 0;
            }
        }
    }

    @media screen and (max-width: 990px) {
        .explain-contents {
            height: auto;
            width: 50%;

            + {
                .explain-contents {
                    padding: 0;
                    border: 0;
                }
            }

            &:nth-child(2) {
                ~ {
                    .explain-contents {
                        margin-top: 16px;
                        border-top: 1px solid var(#d8d8d8);
                    }
                }
            }
        }

        .explain-bar {
            height: auto;
            flex-wrap: wrap;
            padding-bottom: 14px;
        }

        .job-subtitle-wrapper {
            flex-direction: column;
            align-items: flex-start;

            .posted {
                margin-left: 0;
                margin-top: 6px;
            }
        }
    }


    @media screen and (max-width: 760px) {
        .job-details-section .theme-btn {
            margin-top: 13px;
            margin-bottom: 15px;
        }
        .detail-page {
            .job-overview-cards {
                display: none;
            }
        }

        .user-name {
            display: none;
        }

        .user-profile {
            margin-right: 0;
        }

        .job-explain {
            margin-left: 0;
        }
    }

    @media screen and (max-width: 730px) {

        .main-container {
            display: flex;
            flex-grow: 1;
            padding-top: 30px;
            flex-direction: column !important;

            .search-type {
                width: 100% !important;
                display: flex;
                flex-direction: column;
                height: 100%;
                flex-shrink: 0;
            }

            .job-info {
                padding-left: 10px !important;

                h3 {
                    font-size: 18px !important;
                }
            }
        }

        // .search-type {
        //     display: none;
        // }

        .searched-jobs {
            padding-left: 0;
        }

        .search-menu {
            div {
                &:not(:last-of-type) {
                    border: 0;
                }
            }
        }

        .job-cards {
            grid-template-columns: repeat(2, 1fr);
        }

        .search-location {
            display: none;
        }
    }


    @media screen and (max-width: 620px) {
        .job-cards {
            grid-template-columns: repeat(1, 1fr);
        }

        .header-menu {
            a {
                &:not(:first-child) {
                    margin-left: 10px;
                }
            }
        }
    }

    @media screen and (max-width: 590px) {
        .header-menu {
            display: none;
        }
    }

    @media screen and (max-width: 520px) {
        .search-bar {
            flex-grow: 1;

            input {
                padding: 0 0 0 30px;
            }
        }

        .search-button {
            margin-left: 16px;
        }

        .searched-bar {
            flex-direction: column;
            align-items: flex-start;
        }

        .searched-sort {
            margin-top: 5px;
        }

        .main-container {
            padding-top: 20px;
        }
    }

    @media screen and (max-width: 380px) {
        .explain-contents {
            width: 100%;
            margin: 0;

            &:nth-child(2) {
                ~ {
                    .explain-contents {
                        margin: 0;
                        border: 0;
                    }
                }
            }
        }

        .wrapper {
            padding: 20px;
        }

        .header {
            padding: 0 20px;
        }
    }

    .w3-animate-bottom {
        position: relative;
        animation: animatebottom 0.4s;
    }

    @keyframes animatebottom {
        from {
            bottom: -300px;
            opacity: 0
        }

        to {
            bottom: 0;
            opacity: 1
        }
    }

    .w3-animate-zoom {
        animation: animatezoom 0.6s
    }

    @keyframes animatezoom {
        from {
            transform: scale(0)
        }

        to {
            transform: scale(1)
        }
    }

    .find-search-image {
        animation: fadein 1.4s ease-in;
    }

    @keyframes fadein {
        0% {
            margin-left: -50px;
            opacity: 0;
        }

        50% {
            opacity: 0.7;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeout {
        0% {
            margin-right: -50px;
            opacity: 0;
        }

        50% {
            opacity: 0.7;
        }

        100% {
            opacity: 1;
        }
    }
}

.JobfavrateIcon i {
    color: white;
    font-size: 22px;
    cursor: pointer;
}

.redHeart {
    color: red !important;
}