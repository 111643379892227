$color_1: #ffff;
$color_2: #fff;
$color_3: #060f24;
$color_4: #FFFFFF;
$color_5: #081c3c;
$font-family_1: boxicons;
$font-family_2: monospace;
$background-color_1: #060f24;
$background-color_2: #fff;

.blog-section {
    .section-title {
        h2 {
            font-size: 38px;
            font-weight: 600;
            max-width: 550px;
            margin: 0 auto 10px;
            line-height: 1;
            text-transform: capitalize;
        }

        p {
            max-width: 750px;
            margin: 0 auto;
        }
    }

    .blog-card {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        margin-bottom: 30px;
        background-color: $background-color_1 !important;

        .blog-text {
            ul {
                li {
                    i {
                        color: $color_1;
                        margin-right: 2px;
                    }

                    list-style: none;
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 14px;
                }
            }

            p {
                margin-bottom: 10px;
                margin-bottom: 10px;
            }

            h3 {
                font-size: 22px;
                margin-bottom: 10px;
                line-height: 1.4;
                color: $color_2;
            }

            .blog-btn {
                color: $color_1;
                font-size: 16px;
            }
        }
    }

    .bx {
        font-family: $font-family_1 !important;
        font-weight: 400;
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        text-rendering: auto;
        display: inline-block;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .blog-text {
        padding: 15px 0 15px 13px;

        a {
            font-size: 15px;
            color: $color_1;
            text-decoration: none;
        }
    }

    .page-link {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        color: $color_3;
        background-color: $background-color_2;
        border: 1px solid #dee2e6;
    }

    .page-item {
        &:not(:first-child) {
            .page-link {
                margin-left: -1px;
            }
        }
    }

    .center {
        text-align: center;
        font-family: $font-family_2;
    }

    .pagination {
        display: inline-block;
        display: flex;
        padding-left: 0;
        list-style: none;
        justify-content: center;

        a.active {
            background: linear-gradient(#081c3c 0%, #21447c 100%);
            color: $color_4;
            border: 1px solid #081c3c;
        }

        a {
            color: $color_5;
            float: left;
            padding: 2px 10px;
            text-decoration: none;
            transition: background-color .5s;
            border: 1px solid #DDD;
            margin: 0 8px;
            font-size: 20px;
            border-radius: 5px;
        }
    }

    .flip-card {
        background-color: transparent;
        width: 100%;
        perspective: 1000px;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 365px;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front,
    .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .flip-card-front {
        background-color: #060f24;
        color: rgb(255, 255, 255);
    }

    .flip-card-back {
        background-color: #060f24;
        color: white;
        transform: rotateY(180deg);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    .blog-text li {
        display: initial;
        margin-right: 15px;
    }

    .blog-details{
        .details-text {
            padding: 20px;
            border-radius: 15px;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    
            li {
                display: block;
                margin-right: 8px;
                margin-top: 10px;
            }
        }
    }
}