$color_1: #FF6F61;
$color_2: #ffffff;
$color_3: #2A293E;
.princing-section{
.pricing-table {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	max-width: 1100px;
	margin: 0 auto;
	background: #ffffff;
	.ptable-item {
		width: 33.33%;
		padding: 0 15px;
		margin-bottom: 30px;
	}
	.ptable-single {
		position: relative;
		width: 100%;
		overflow: hidden;
		background: #f6f8fa;
		transition: .5s;
		&:hover {
			box-shadow: 0 5px 7px #c2c2c2;
    transition: .5s;
		}
	}
	.ptable-header {
		position: relative;
		width: 100%;
		text-align: center;
		overflow: hidden;
		margin: 0 30px;
		padding: 30px 0 45px 0;
		width: auto;
		// background: #2A293E;
		background-color: #010c29 !important;
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			width: 0;
			height: 0;
			border-bottom: 100px solid #f6f8fa;
			right: 50%;
			border-right: 250px solid transparent;
		}
		&::after {
			content: "";
			position: absolute;
			bottom: 0;
			width: 0;
			height: 0;
			border-bottom: 100px solid #f6f8fa;
			left: 50%;
			border-left: 250px solid transparent;
		}
	}
	.ptable-body {
		position: relative;
		width: 100%;
		text-align: center;
		overflow: hidden;
		padding: 20px 0;
	}
	.ptable-footer {
		position: relative;
		width: 100%;
		text-align: center;
		overflow: hidden;
		padding-bottom: 30px;
	}
	.ptable-status {
		position: relative;
		width: 100%;
		text-align: center;
		margin-top: -30px;
		span {
			position: relative;
			display: inline-block;
			width: 50px;
			height: 30px;
			padding: 5px 0;
			text-align: center;
			color: #fff;
			font-size: 14px;
			font-weight: 300;
			letter-spacing: 1px;
			background: #2A293E;
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				width: 0;
				height: 0;
				border-bottom: 10px solid #2a293e;
				right: 50%;
				border-right: 25px solid transparent;
			}
			&::after {
				content: "";
				position: absolute;
				bottom: 0;
				width: 0;
				height: 0;
				border-bottom: 10px solid #2a293e;
				left: 50%;
				border-left: 25px solid transparent;
			}
		}
	}
	.ptable-title {
		position: relative;
		width: 100%;
		text-align: center;
		h2 {
			color: $color_2;
			font-size: 24px;
			font-weight: 300;
			letter-spacing: 2px;
		}
	}
	.ptable-price {
		position: relative;
		width: 100%;
		text-align: center;
		h2 {
			margin: 0;
			color: $color_2;
			font-size: 45px;
			font-weight: 700;
			margin-left: 15px;
			small {
				position: absolute;
				font-size: 18px;
				font-weight: 300;
				margin-top: 16px;
				margin-left: -15px;
			}
			span {
				margin-left: 3px;
				font-size: 16px;
				font-weight: 300;
			}
		}
	}
	.ptable-description {
		position: relative;
		width: 100%;
		text-align: center;
		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				color: $color_3;
				font-size: 14px;
				font-weight: 300;
				letter-spacing: 1px;
				padding: 7px;
				border-bottom: 1px solid #dedede;
				&:last-child {
					border: none;
				}
			}
		}
	}
	.ptable-action {
		position: relative;
		width: 100%;
		text-align: center;
		a {
			display: inline-block;
			padding: 10px 20px;
			color:#fff;
			font-size: 16px;
			font-weight: 600;
			letter-spacing: 2px;
			border-radius: 10px;
			text-decoration: none;
			// background: #2A293E;
			background-color: #010c29;
			&:hover {
				color:#fff;
				background: #2443ac;
			}
		}
	}
	.ptable-item.featured-item {
		.ptable-header {
			background:#060f24;
		}
		.ptable-action {
			a {
				color: #fff;
				background:#2A293E;
				&:hover {
					color:#fff;
					background: #2A293E;
				}
			}
		}
	}
}
}
@media (max-width: 992px) {
	.pricing-table {
		.ptable-item {
			width: 33.33% !important;
		}
	}
}
@media (max-width: 768px) {
	.pricing-table {
		.ptable-item {
			width: 100% !important;
		}
	}
}
@media (max-width: 576px) {
	.pricing-table {
		.ptable-item {
			width: 100% !important;
		}
	}
}
@media (max-width: 480px) {
	.pricing-table {
		.ptable-item {
			width: 100% !important;
		}
	}
}
