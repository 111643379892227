$color_1: #444;
$color_2: #acacac;
$color_3: #333;
$color_4: #aaa;
$color_5: #fff;
$background-color_1: #fff;
$background-color_2: #f0f0f0;
$background-color_3: #081c3c;
$border-color_1: #081c3c;


@keyframes animateright {
    from {
        right: -300px;
        opacity: 0;
    }

    to {
        right: 0;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }

    to {
        top: 0;
        opacity: 1;
    }
}

.signin-section {
    .login-section {
        position: relative;
        width: 100%;
        background-color: $background-color_1;
        min-height: 100vh;
        overflow: hidden;

        &:before {
            content: "";
            position: absolute;
            height: 2000px;
            width: 2000px;
            top: -10%;
            right: 48%;
            transform: translateY(-50%);
            background-image: linear-gradient(#081c3c 0%, #21447c 100%);
            transition: 1.8s ease-in-out;
            border-radius: 50%;
            z-index: 6;
        }
    }

    .forms-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    .signin-signup {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 75%;
        width: 50%;
        transition: 1s 0.7s ease-in-out;
        display: grid;
        grid-template-columns: 1fr;
        z-index: 5;
    }

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0rem 5rem;
        transition: all 0.2s 0.7s;
        overflow: hidden;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    form.sign-up-form {
        opacity: 0;
        z-index: 1;
    }

    form.sign-in-form {
        z-index: 2;
    }

    .title {
        font-size: 2.2rem;
        color: $color_1;
        margin-bottom: 10px;
    }

    .input-field {
        max-width: 380px;
        width: 100%;
        background-color: $background-color_2;
        margin: 10px 0;
        height: 55px;
        border-radius: 55px;
        grid-template-columns: 15% 85%;
        padding: 0 0.4rem;
        position: relative;

        i {
            text-align: center;
            line-height: 55px;
            color: $color_2;
            transition: 0.5s;
            font-size: 1.1rem;
        }

        input {
            background: none;
            outline: none;
            border: none;
            line-height: 1;
            font-weight: 600;
            font-size: 1.1rem;
            color: $color_3;

            &::placeholder {
                color: $color_4;
                font-weight: 500;
            }
        }
    }

    .social-text {
        padding: 0.7rem 0;
        font-size: 1rem;
    }

    .social-media {
        display: flex;
        justify-content: center;
    }

    .social-icon {
        height: 46px;
        width: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0.45rem;
        color: $color_3;
        border-radius: 50%;
        border: 1px solid #333;
        text-decoration: none;
        font-size: 1.1rem;
        transition: 0.3s;

        &:hover {
            color: $color_5;
            border-color: $border-color_1;
            background: #081c3c;
        }
    }

    .btn {
        width: 150px;
        background-color: $background-color_3;
        border: none;
        outline: none;
        height: 49px;
        border-radius: 49px;
        color: $color_5;
        text-transform: uppercase;
        font-weight: 600;
        margin: 10px 0;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            background-color: $background-color_3;
            opacity: .8;
        }
    }

    .panels-container {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        animation-name: fadeInLeft;
    }

    .image {
        width: 100%;
        transition: transform 1.1s ease-in-out;
        transition-delay: 0.4s;
    }

    .panel {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-around;
        text-align: center;
        z-index: 6;

        .content {
            color: $color_5;
            transition: transform 0.9s ease-in-out;
            transition-delay: 0.6s;
        }

        h3 {
            font-weight: 600;
            line-height: 1;
            font-size: 1.5rem;
        }

        p {
            font-size: 0.95rem;
            padding: 0.7rem 0;
        }
    }

    .left-panel {
        pointer-events: all;
        padding: 3rem 17% 2rem 12%;
        // img{
        //     display: none;
        // }
    }

    .right-panel {
        pointer-events: none;
        padding: 3rem 12% 2rem 17%;

        .image {
            transform: translateX(800px);
        }

        .content {
            transform: translateX(800px);
        }
    }

    .btn.transparent {
        margin: 0;
        background: none;
        border: 2px solid #fff;
        width: 130px;
        height: 41px;
        font-weight: 600;
        font-size: 0.8rem;
    }

    .login-section.sign-up-mode {
        &:before {
            transform: translate(100%, -50%);
            right: 52%;
        }

        .left-panel {
            .image {
                transform: translateX(-800px);
            }

            .content {
                transform: translateX(-800px);
            }

            pointer-events: none;
        }

        .signin-signup {
            left: 25%;
        }

        form.sign-up-form {
            opacity: 1;
            z-index: 2;
        }

        form.sign-in-form {
            opacity: 0;
            z-index: 1;
        }

        .right-panel {
            .image {
                transform: translateX(0%);
            }

            .content {
                transform: translateX(0%);
            }

            pointer-events: all;
        }
    }

    .input-group-text {
        background-color: #e8f0fe;
        border-radius: 20px;
        border: 1px solid #e8f0fe;

    }

    input#password {
        border: none;
    }

    .form-control.is-valid,
    .was-validated .form-control:valid {
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        border: none;
    }

    .animate-top {
        animation: animatetop 2s !important;
    }

    .animate-right {
        position: relative;
        animation: animateright 2s !important;
    }

    .input-group {
        max-width: 420px;
        width: 100%;
        // height: 50px;
    }

    input {
        border: none;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .content p {
        color: #Ffff;
    }

    .content h3 {
        color: #Ffff;
    }

    .form-control.is-valid:focus,
    .was-validated .form-control:valid:focus {
        box-shadow: none !important;
    }

    .form-control {
        background-color: #e8f0fe !important;
    }

    @media (max-width: 870px) {
        .login-section {
            min-height: 800px;
            height: 100vh;

            &::before{
                width: 1500px;
                height: 1500px;
                transform: translateX(-50%);
                // left: 30%;
                // bottom: 68%;
                left: 47%;
                bottom: 47%;
                right: initial;
                top: initial;
                transition: 2s ease-in-out;
            }
            img{
                display: none ;
            }
        }

        .signin-signup {
            width: 100%;
            top: 95%;
            transform: translate(-50%, -100%);
            transition: 1s 0.8s ease-in-out;
            left: 50%;
        }

        .login-section.sign-up-mode {
            .signin-signup {
                left: 50%;
                top: 5%;
                transform: translate(-50%, 0);
            }

            &:before {
                transform: translate(-50%, 100%);
                bottom: 32%;
                right: initial;
            }

            .left-panel {
                .image {
                    transform: translateY(-300px);
                }

                .content {
                    transform: translateY(-300px);
                }
            }

            .right-panel {
                .image {
                    transform: translateY(0px);
                }

                .content {
                    transform: translateY(0px);
                }
            }
        }

        .panels-container {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 2fr 1fr;
        }

        .panel {
            // flex-direction: row;
            flex-direction:column !important;
            justify-content: space-around;
            align-items: center;
            padding: 2.5rem 8%;
            grid-column: 1 / 2;

            .content {
                // padding-right: 15%;
                padding-right: 0%;
                transition: transform 0.9s ease-in-out;
                transition-delay: 0.8s;
            }

            h3 {
                font-size: 1.2rem;
            }

            p {
                font-size: 0.7rem;
                padding: 0.5rem 0;
            }
        }

        .right-panel {
            grid-row: 3 / 4;

            .image {
                transform: translateY(300px);
            }

            .content {
                transform: translateY(300px);
            }
        }
        .left-panel {
            grid-row: 1 / 2;
        }
        .image {
            // width: 200px;
            width: 250px;
            transition: transform 0.9s ease-in-out;
            transition-delay: 0.6s;
        }
        .btn.transparent {
            width: 110px;
            height: 35px;
            font-size: 0.7rem;
        }
    }

    @media (max-width: 570px) {
        .signin-section .login-section:before {
            width: 1500px;
            height: 1500px;
            transform: translateX(-50%);
            left: 47% !important;
            bottom: 47% !important;
            right: initial;
            top: initial;
            transition: 2s ease-in-out;
        }
        form {
            padding: 0 1.5rem;
        }

        .image {
            display:block;
        }

        .panel {
            .content {
                padding: 0.5rem 1rem;
            }
        }

        .login-section {
            padding: 1.5rem;

            // &:before {
            //     bottom: 72%;
            //     left: 50%;
            // }
        }

        .login-section.sign-up-mode {
            &:before {
                bottom: 28%;
                left: 50%;
            }
        }
    }
}

.signin-section .btn.transparent {
    margin: 0 0 0 14px !important;
}
.signin-section a{
    padding-top: 9px !important;

}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}
.password-eye {
    background-color: #e8f0fe !important;
}