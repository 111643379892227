.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: #061322 !important;
    border-color: var(--bs-nav-tabs-link-active-border-color) !important;
    color: #ffff !important;
}
.nav-tabs .nav-link {
    color:#061322;
}
.file-upload {
	position: relative;
	display: inline-block;
}

.file-upload__label {
  display: block;
  padding: 1em 2em;
  color: #fff;
  background: #061322 !important;
  border-radius: .4em;
  transition: background .3s;
  
  &:hover {
     cursor: pointer;
     background-color: #59C4BC !important;
     border-color: #59C4BC !important;
  }
}
    
.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width:0;
    height: 100%;
    opacity: 0 !important;
}
.downloadBtnDiv {
    padding: 15px 20px;
    cursor: pointer;
    border-radius: 0.4em;
    border: 2px solid #061322;
}
.customSelect {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    height: 42px;
}
.form-check-input:checked {
    background-color: #061322 !important;
    border-color: #061322 !important;
}
.setting-profile-css{
    // background-color: #dedede;
    box-shadow: rgb(126, 126, 126) 0px 0px 5px;
}

.browers{
   z-index: 000000;
}
.dropdown-issue{
    z-index: 999999;
}
.public-DraftStyleDefault-ltr {
    text-align: start !important;
}