$color_1: #707070;
$color_2: #001935;
$color_3: black;
$color_4: white;
$color_5: #ffffff;
$color_6: #fff;
$color_7: #ffff;
$color_8: #2443ac;
$color_9: rgb(36, 67, 172);
$color_10: #212529;
$font-family_1: "IBM Plex Sans", sans-serif;
$font-family_2: "Catamaran", sans-serif;
$font-family_3: 'boxicons';
$background-color_1: transparent;
$background-color_2: var(--blue);
$background-color_3: rgba(var(--blue-rgb), 0.15);
$border-color_1: #dc3545;
$border-color_2: #198754;
$border-color_3: rgba(0, 1, 0, 1);
$border-color_4: var(--blue);
$border-color_5: rgba(var(--blue-rgb), 0.15);

// @import "https://fonts.googleapis.com/css?family=Catamaran:400,600|IBM+Plex+Sans:400,500,500i,600,700&display=swap";

/* li.nav-item a {
    color: black !important;
  } */
/* Validate form css start */
/* Validate form css end */
#root~iframe[style*="2147483647"] {
    z-index: -1000 !important;
    display: none;
}

.css-1fdsijx-ValueContainer {
    padding: 0 !important;
}

.l {
    border: 1px solid red;
}

body {
    font-size: 17px;
    font-family: $font-family_1;
    color: $color_1;
    line-height: 1.6;
}

.bi-x-circle-fill {
    color: gray !important;
    z-index: 9999 !important;
}

nav.navbar.navbar-expand-lg.bg-primary.py-3 {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99;

}

// =============Eye============
.eye_btn {
    border: none;
    outline: none;
    background-color: #001935;
    border: 1px solid #001935;
    width: 90px;
    padding: 5px 0px;
    border-radius: 5px;
    color: white;
    font-weight: 600;

    &:hover {
        background-color: white;
        border: 1px solid #001935;
        color: #001935;

    }
}


// ============================
// ===============
.main-pdf-box {
    display: flex;
    column-gap: 5rem;
    flex-wrap: wrap;

    .pdf-box {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .pdf-icon {
            position: relative;

            img {
                width: 120px;
            }

            &:hover {
                .show-button {
                    opacity: 1;
                }

                // .show-box{
                //     opacity: 1;
                // }

            }
        }

        //    .show-box{
        //     opacity: 0;
        //     transition: opacity .35s ease;
        //     position: absolute;
        //     transform: translate(66px, -41px);
        //     background-color:rgb(236, 248, 2);
        //     width: 250px;
        //     height: 200px;
        //     z-index:9 !important;
        //     display: inline-block;
        //    }
        .show-button {
            opacity: 0;
            transition: opacity .35s ease;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(234, 231, 231, 0.35);
            width: 216px;
            height: 200px;

            // border-radius: 11% 37% 15% 13%;
            .preview-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: absolute !important;
                top: 50% !important;
                right: 25px !important;
                transform: translateY(-50%);
                display: inline;

                i {
                    display: block;
                    margin-bottom: 10px;
                    font-size: 18px;
                    color: #001935;
                    cursor: pointer;

                    &:nth-child(1) {
                        margin-left: 4px;
                    }

                    &:nth-child(3) {
                        margin-left: 2px;
                    }
                }
            }
        }
    }
}

.main-pdf-box1 {
    display: flex;
    column-gap: 2rem;
    flex-wrap: wrap;

    .pdf-box {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 190px;

        .pdf-icon {
            position: relative;

            img {
                width: 120px;
            }

            &:hover {
                .show-button {
                    opacity: 1;
                }
            }
        }

    }
}

// ===============
.form-flag-height38 .iti__selected-flag {
    height: 38px;
}

.iti__selected-flag {
    max-height: 58px;
}

.iti__flag-container {
    height: 40px;
}

img {
    max-width: 100%;
}

.bg-primary {
    background-color: #010c29 !important;
}

.pt-100 {
    padding-top: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pt-50 {
    padding-top: 50px;
}


a {
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        text-decoration: none;
    }
}

p {
    font-size: 17px;
    color: $color_1;
    line-height: 1.8;
}

button {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    background-color: $background-color_1;

    &:focus {
        outline: 0;
    }
}

// .l{
//     border: 1px solid red;
// }

h1 {
    color: $color_2;
    font-weight: bold;
}

h2 {
    color: $color_2;
    font-weight: bold;
}

h3 {
    color: $color_2;
    font-weight: bold;
    font-size: 22px;
}

h4 {
    color: $color_2;
    font-weight: bold;
}

h5 {
    color: $color_2;
    font-weight: bold;
}

h6 {
    color: $color_2;
    font-weight: bold;
}

.form-control {
    &:focus {
        -webkit-box-shadow: 0 0 0 0;
        box-shadow: 0 0 0 0;
    }
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

.pt-100 {
    padding-top: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pb-100 {
    padding-bottom: 100px;
}

.section-title {
    margin-bottom: 60px;

    h2 {
        font-size: 38px;
        font-weight: 600;
        max-width: 550px;
        margin: 0 auto 20px;
        line-height: 1;
        text-transform: capitalize;
    }

    p {
        max-width: 750px;
        margin: 0 auto;
        color: $color_3;
    }
}

.job-style-two {
    p {
        color: $color_4;
    }
}

.condidate-item {
    .candidate-text {
        .bottom-text {
            a {
                i {
                    &:hover {
                        background: linear-gradient(#081c3c 0%, #21447c 100%);
                        color: $color_5;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                        background: #fd1616;
                        color: $color_5;
                        -webkit-transition: 0.5s;
                        transition: 0.5s;
                    }

                    color: $color_6;
                    border: 1px dashed #fff;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 50px;
                }

                float: right;
            }

            border-top: 1px dashed #e2caca;
            margin-top: 14px;
            padding: 14px 0 0;
            line-height: 1;

            p {
                margin-bottom: 0;
                font-size: 16px;
                float: left;
                color: $color_6;

                i {
                    font-size: 18px;
                    top: 3px;
                    position: relative;
                }
            }
        }

        background: #001935;
        position: absolute;
        bottom: -98px;
        left: 0;
        right: 0;
        max-width: 95%;
        margin: auto;
        -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        padding: 20px;
        border-radius: 5px;
        opacity: .8;

        h3 {
            margin-bottom: 15px;

            a {
                color: $color_6;
            }
        }

        ul {
            padding-left: 0;
            margin-bottom: 0;

            li {
                list-style: none;
                font-size: 15px;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    text-align: center;
    max-width: 300px;
    position: relative;
    margin-bottom: 100px;
    border: 4px solid #001935;
    border-radius: 20px;
    box-shadow: 5px 10px #001935;

    .candidate-img {
        border-radius: 10px;

        img {
            border-radius: 10px;
        }
    }

    .candidate-social {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        -webkit-transition: 0.5s;
        transition: 0.5s;

        a {
            i {
                background: #fff;
                color: $color_9;
                width: 35px;
                height: 35px;
                border-radius: 30px;
                line-height: 35px;
                margin: 0 3px;
                -webkit-transition: 0.5s;
                transition: 0.5s;

                &:hover {
                    background: #001935;
                }
            }
        }
    }

    &:hover {
        .candidate-social {
            bottom: 120px;
        }

        .candidate-text {
            h3 {
                a {
                    color: $color_4;
                }
            }
        }
    }
}

.theme-btn {
    .default-btn {
        font-size: 18px;
        font-weight: 500;
        font-family: $font-family_2;
        background: linear-gradient(#081c3c 0%, #21447c 100%);
        color: $color_5;
        margin-right: 15px;
        border: 1px solid transparent;
        padding: 10px 25px;
        display: inline-block;
        border-radius: 5px;

        &:hover {
            background: #001935;
            border: 1px solid #ffffff;
        }
    }
}

.carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    position: relative;
    left: -40px;
    top: -12px;
}

.carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    position: relative;
    right: -40px;
    top: -12px;
}

.carousel-control-next {
    &:hover {
        color: $color_6;
        text-decoration: none;
        outline: 0;
        opacity: 1;
        transform: scale(1.04);
        transition: 0.2s;
    }

    color: $color_6;
    text-decoration: none;
    outline: 0;
    opacity: 1 !important;
    transition: 0.2s;
}

.carousel-control-prev {
    &:focus {
        color: $color_6;
        text-decoration: none;
        outline: 0;
        opacity: 1;
        transform: scale(1.04);
        transition: 0.2s;
    }

    &:hover {
        color: $color_6;
        text-decoration: none;
        outline: 0;
        opacity: 1;
        transform: scale(1.04);
        transition: 0.2s;
    }

    color: $color_6;
    color: $color_6;
    text-decoration: none;
    text-decoration: none;
    outline: 0;
    outline: 0;
    opacity: 1 !important;
    opacity: 1 !important;
    transition: 0.2s;
    transition: 0.2s;
}

ol {
    padding-left: 0;
}

ul {
    padding-left: 0;
}

a.nav-link.active {
    color: $color_6 !important;
}

a.nav-link {
    color: $color_6 !important;
}

.navbar {
    .navbar-toggler:focus {
        text-decoration: none;
        outline: 0;
        box-shadow: none !important;
    }

    .navbar-toggler-icon {
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        vertical-align: middle;
        background-image: var(--bs-navbar-toggler-icon-bg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        filter: invert(1) !important;
    }

    .signin-btn {
        color: #ffffff;
        font-family: "Catamaran", sans-serif;
        font-size: 16px;
        font-weight: 600;
        padding: 5px 20px;
        background: #2443ac;
        border-radius: 5px;
    }
}

.navbar-area {
    .main-nav {
        background: #010c29;
        padding: 20px 0;
        position: relative;

        .navbar {
            padding: 0;

            .navbar-brand {
                padding: 0;
            }

            .navbar-nav {
                .nav-item {
                    padding: 10px 0;

                    .nav-link {
                        text-transform: capitalize;
                        font-family: $font-family_2;
                        font-size: 17px;
                        color: $color_7;
                    }

                    .nav-link.dropdown-toggle {
                        &::after {
                            border: 0;
                            content: '\ea4a';
                            font-family: $font-family_3;
                            vertical-align: middle;
                            line-height: 1;
                        }
                    }

                    .nav-link.active {
                        color: $color_8;
                    }

                    .dropdown-menu {
                        padding: 0;
                        border-radius: 0;
                        background: none;

                        .nav-item {
                            padding: 0;

                            .nav-link {
                                border-bottom: 1px dashed #4d5877;
                                background: #2443ac;
                                font-size: 15px !important;

                                &:hover {
                                    color: $color_5;
                                    background: #657294 !important;
                                }
                            }

                            .nav-link.active {
                                color: $color_5;
                                background: #657294 !important;
                            }

                            &:last-child {
                                .nav-link {
                                    border-bottom: 0;
                                }
                            }
                        }
                    }
                }
            }

            .other-option {
                .signin-btn {
                    color: $color_5;
                    font-family: $font-family_2;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 5px 20px;
                    background: #2443ac;
                    border-radius: 5px;

                    &:hover {
                        background: #ffffff;
                        color: $color_2;
                    }
                }

                .signup-btn {
                    color: $color_5;
                    font-family: $font-family_2;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 5px 20px;
                    background: #2443ac;
                    border-radius: 5px;
                    background: none;
                    padding: 0;
                    margin-right: 10px;

                    &:hover {
                        background: #ffffff;
                        color: $color_2;
                        color: $color_8;
                        background: none;
                    }
                }
            }
        }
    }
}

.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    -webkit-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    width: 100% !important;
}

i.bx.bx-filter-alt {
    color: $color_7;
}

i.bx.bx-location-plus {
    color: $color_6;
}

.condidate-slider {
    .owl-dots {
        line-height: 1;
        margin-top: 25px !important;

        .owl-dot {
            span {
                background: #fdc8bb !important;
                margin: 0 5px;
            }
        }

        .owl-dot.active {
            span {
                background: #fd1616 !important;
            }
        }
    }
}

.input-group {
    .invalid-feedback {
        -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
        order: 8;
    }
}

.was-validated {
    .react-select.is-invalid {
        div {
            border-color: $border-color_1 !important;
            border-radius: 5px !important;

            &:hover {
                border-color: $border-color_1 !important;
            }
        }
    }

    .react-select.is-valid {
        div {
            border-color: $border-color_2 !important;
            border-radius: 5px !important;

            &:hover {
                border-color: $border-color_2 !important;
            }
        }
    }

    .react-select {
        div {
            &:hover {
                border-color: $border-color_3 !important;
            }
        }
    }

    .form-control {
        &:invalid {
            border-color: $border-color_1 !important;
        }
    }
}

.needs-validation {
    div {
        &:has(.invalid-custom-feedback) {
            .invalid-feedback {
                display: none;
            }
        }
    }
}

.form-control.is-invalid {
    border-color: $border-color_1 !important;
}

.pagination-sm {
    .page-item.previous.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .page-item.next.disabled {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .page-item.previous {
        .page-link {
            width: 74px;
            font-weight: 500;
        }
    }

    .page-item.next {
        .page-link {
            width: 74px;
            font-weight: 500;
        }
    }

    .page-item.active {
        .page-link {
            background-color: $background-color_2;
            border-color: $border-color_4;
            color: $color_6;

            &:hover {
                background-color: $background-color_2;
                border-color: $border-color_4;
                color: $color_6;
            }
        }
    }

    .page-item {
        &:hover {
            .page-link {
                background-color: $background-color_3;
                border-color: $border-color_5;
            }
        }

        .page-link {
            height: 30px;
            min-width: 30px;
            text-decoration: none;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            border: 1px solid #dee2e6;
            margin-left: 2px;
            cursor: pointer;
            text-align: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            border-radius: 0.25rem;
            font-size: 14px;
            color: $color_10;
        }
    }
}

@keyframes rotate {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.footer-area {
    &::before {
        right: 155px;
        top: 180px;
        position: absolute;
        content: '';
        background-image: url('./img/footer.png');
        background-position: center center;
        background-size: cover;
        width: 140px;
        height: 138px;
        right: 60px;
        top: 70px;
        opacity: .2;
        -webkit-animation: rotate 30s infinite linear;
        animation: rotate 30s infinite linear;
    }

    .footer-widget.pl-60 {
        padding-left: 35px;
        padding-left: 0;
        padding-left: 0;
    }

    .footer-widget {
        p {
            font-size: 15px;
            font-size: 15px;
            color: $color_6;
        }

        h3 {
            &::before {
                position: absolute;
                content: '';
                background: #ffffff;
                height: 2px;
                width: 50px;
                left: 0;
                bottom: -15px;
            }

            color: $color_5;
            position: relative;
            margin-bottom: 40px;
            font-weight: 600;
        }

        a {
            color: $color_6 !important;
        }


        span {
            color: $color_7;
        }

        li {
            list-style: none !important;
            color: $color_6;
        }

        .footer-social {
            a {

                i {
                    color: #060f24;
                    background: #fff;
                    font-size: 18px;
                    width: 35px;
                    height: 35px;
                    text-align: center;
                    border-radius: 50px;
                    line-height: 35px;
                    -webkit-transition: .5s;
                    transition: .5s;

                    &:hover {
                        background: #2443ac;
                        color: #fff;
                    }
                }
            }
        }

        ul {
            li {
                &:hover {
                    color: #fff !important;
                }

                a {
                    &:hover {
                        color: #fff !important;
                    }

                    i {
                        color: $color_7;
                        top: 2px;
                        position: relative;
                    }
                }
            }
        }
    }

    background: #060f24;
    position: relative;

    .bx {
        color: $color_7;
        margin-right: 7px;
    }

    i.bx.bx-chevrons-right.bx-tada:hover {
        color: #fff;
    }

    .footer-area .footer-widget a:hover {
        color: #fff !important;
    }

    .footer-area .footer-widget .footer-social a i:hover {
        background: #060f24 !important;
        color: #ffff !important;
        border: 1px solid #fff !important;
    }
}

@media screen and (max-width: 768px) {
    .navbar-nav {
        li {
            display: table-cell;
            position: inherit;
            text-align: left !important;
        }

    }

    footer {
        position: relative;
        text-align: left !important;
    }
}


.priview_pdf {
    width: 100%;
    max-width: 100%;
    height: 800px;
}

.manage-pdf {
    width: 100%;
    max-width: 90%;
    height: 220px;
    aspect-ratio: 16/9;
}

.main-profile {

    // padding: 31px 17px;
    // box-shadow: 5px 5px 10px #888888;
    .profile-image {

        .profile_pic {
            width: 200px;
            max-height: 200px;
            display: inline-block;

        }

        .file-upload {
            display: none;
        }

        .circle {
            border-radius: 100% !important;
            overflow: hidden;
            width: 128px;
            height: 128px;
            border: 2px solid rgba(255, 255, 255, 0.2);
            position: absolute;
            top: 0px;
            position: relative;
            display: flex;
            left: 0px;
            justify-content: center;
            box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.1);
        }

        img {
            max-width: 100%;
            height: auto;

        }

        .p-image {

            position: absolute;
            bottom: 12px;
            right: -4px;
            color: #666666;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background: rgba(255, 255, 255, 0.75);
            box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.5);
        }

        .p-image:hover {
            transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
        }

        .upload-button {
            font-size: 1.2em;
        }

        .upload-button:hover {
            transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
            color: #999;
        }
    }
}

section.about-section.py-5 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    .account {
        box-shadow: 5px 5px 10px #888888;
        padding: 88px;
        background-color: #010c29;

        h2 {
            color: #ffff !important;
        }

        p {
            color: #Ffff;
        }
    }

    .close-button-sign-up {
        margin-left: auto;
    }

}

.input-group.password-control.mb-2 {
    position: relative;
}

.password-eye {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 99;
    left: auto;

}
// ==================================
.upload_img1{
    position: relative;
    img{
        width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: revert;
    }
    .fa-solid{
        position: absolute;
        top: 10px;
        right: 10px;
        color: red;
        font-size: 20px;
        cursor: pointer;
    }
}
.upload_img2{
    position:relative;
    img{
    width: 100%;
    height: 100%;
    max-height: 500px;
    object-fit: revert;
    }
    .fa-solid{
        position: absolute;
        top: 10px;
        right: 10px;
        color: red;
        font-size: 20px;
        cursor: pointer;
    }
   
}
.upload-box{
    width: 100%;
    height: 250px;
    max-height: 100%;
    input[type="file"]{
        visibility:hidden;
    }
.uploadIcon{
    display: block;
    
    img{
        width: 100px;
    }
}
}

.password-eye img {
    width: 100%;
    max-width: 30px;
}

.needs-validation .form-control {
    background-image: none !important;
}

.react-select {
    border-radius: 0 !important;
}

.was-validated .react-select.is-invalid div {
    border-color: #dc3545 !important;
    border-radius: 5px !important;
}

.was-validated .react-select.is-invalid div:hover {
    border-color: #dc3545 !important;
}

.was-validated .react-select.is-valid div {
    border-color: #198754 !important;
    border-radius: 5px !important;
}

.was-validated .react-select.is-valid div:hover,
.was-validated .react-select.is-valid div:active {
    border-color: #198754 !important;
}

.was-validated .react-select div:hover {
    border-color: rgba(var(--dark-blue-rgb), 0.5) !important;
}

.modal-dialog-scrollable {
    height: auto !important;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.custom-button {
    margin-left: 30px;
    width: 10%;
}

.main-pdf-box1 .pdf-box:hover {
    background: #0000001f !important;
    // background-image: url("../../../public/images/test1.avif") !important;
    padding: 25px;
    border-radius: 10px !important;
}
.showBtnNew{
    opacity: 0;
    position: absolute;
    top: 45%;
    color: white !important;
    left: 26%;
    background: #001935;
    border-radius: 10px;
    padding: 4px 20px;
    cursor: pointer;
}
.main-pdf-box1 .pdf-box:hover .showBtnNew{
    opacity: 1 !important;
}
.main-pdf-box1 .pdf-box{
    padding: 25px;
}
.main-pdf-box1 .pdf-box:hover .pdf-icon img{
    filter: blur(3px) !important;
}

@media screen and (max-width:768px) {
    .dBlock{
        display: block;
    }
    .dNone{
        display: none;
    }
    .chat {
      border: none !important;
    }
    .cursorPointer{
        cursor: pointer !important;
    }
}