$color_1: #f1f1f1;
$color_2: #707070;

.about-section {
    .about-text {
        .section-title {
            margin-bottom: 25px;
        }

        p {
            margin-bottom: 25px;
        }
    }
}

.theme-dark {
    p {
        color: $color_1;
    }
}

p {
    font-size: 17px;
    color: $color_2;
    line-height: 1.8;
    // text-align: justify;
}