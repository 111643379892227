$color_1: #707070;
$color_2: #000000;
$color_1: var(--N500);
$color_2: black;
$color_3: #707070;

.companyDetails {
    .banner-section-contactnew {
        background-image: url("img/cb-h5.jpg");
        background-position: center center;
        background-size: cover;
        position: relative;
        z-index: 0;
        height: 300px;

        .bgWhiteImg {
            position: absolute;
            bottom: -55px;

            .company-logo {
                background: #ffffff;
                width: 100px;
                height: 100px;
                text-align: center;
                line-height: 100px;
                border-radius: 100px;
                border: 1px dashed;
                position: relative;
                top: -8px;
            }
        }
    }

    .companyDetailsDiv h1 {
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.5;
        color: #000;
        margin-bottom: 0px;
    }

    .companyDetailsDiv p {
        font-size: 18px;
        margin-bottom: 0px;
        color: #000;
        font-weight: 500;
    }

    .followBtnDiv a {
        background-color: #2443ac;
        padding: 14px 28px;
        color: white;
        border-radius: 25px;
        font-weight: 600;
    }
    .details-text {
        padding: 20px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
    }
   

.key-highlights {
	padding: 20px;
	margin-bottom: 16px;
	padding: 26px 28px !important;
	.key-highlights-title {
		h2 {
			font-weight: 700;
			font-size: 16px;
			line-height: 20px;
			color: $color_1 !important;
		}
	}
	.key-highlights-body {
		.common-card {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			padding-top: 22px;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			.common-card-icon {
				width: 48px;
				height: 48px;
				text-align: center;
				img {
					width: 46px;
					height: 46px;
					border-radius: 100px;
					border: 1px solid #e7e7f1;
				}
			}
			.common-card-desc {
				padding-left: 10px;
			}
		}
		.name {
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			color: $color_2;
		}
		.label {
			margin-top: 6px;
			font-weight: 500;
			font-size: 12px;
			line-height: 15px;
			color: $color_1;
		}
	}
}


.awards-recog {
	margin-bottom: 16px;
	padding: 20px;
	padding: 26px 28px !important;
	.awards-title {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		.awards-img {
			width: 40px;
			height: 30px;
			margin-right: 16px;
		}
	}
	.awards-details {
		margin-top: 20px;
	}
	.item {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		position: relative;
		&:first-of-type {
			&:before {
				position: relative;
				left: -1px;
				display: block;
				content: "";
				width: 8px;
				height: 8px;
				border-width: 4px;
				border-style: solid;
				border-radius: 12px;
				color: $color_1;
				z-index: 2;
			}
		}
		&:before {
			position: relative;
			left: 0px;
			top: 2px;
			color: $color_1;
			content: "\25CF";
			font-size: 10px;
		}
		&:first-child {
			>div {
				margin-left: 10px;
			}
		}
		&:after {
			position: absolute;
			left: -4px;
			top: 14px;
			content: "";
			border-left: 1px solid black;
			margin-left: 6px;
			height: 96%;
		}
		&:nth-child(5) {
			&:after {
				display: none !important;
			}
		}
	}
	.desc {
		margin-left: 16px;
		display: inline-block;
		vertical-align: text-top;
		width: calc(100% - 30px);
	}
	.year {
		margin-bottom: 2px;
		font-weight: 700;
		font-size: 14px;
		line-height: 18px;
		color: $color_2;
	}
	.title {
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		color: $color_3;
		margin-bottom: 20px;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}
.theme-btn {
    margin-top: 20px;
}
ul {
    list-style: none;
}
}