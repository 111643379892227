$color_1: #060f24;
$color_2: #3c4858;
$color_3: #8492a6;
$color_4: #495057;
$color_5: #6c757d;
$color_6: #fff;
$color_7: #ffff;
$background-color_1: #f8f9fc;
$background-color_2: #fff;
$background-color_3: #060f24;
$border-color_1: #495057;
.contact-section .contact-item.mt-40 {
    width: 100%;
}
.contact-section .float-left {
  width: 24% !important;
}
// .contact-details {
//     width: 80% !important;
// }
.contact-section {
    i.mdi.mdi-twitter {
        color: $color_1;
        font-size: 23px;
    }

    i.mdi.mdi-whatsapp {
        color: $color_1;
        font-size: 23px;
    }

    i.mdi.mdi-instagram {
        color: $color_1;
        font-size: 23px;
    }

    i.mdi.mdi-linkedin {
        color: $color_1;
        font-size: 23px;
    }

    i.mdi.mdi-facebook {
        color: $color_1;
        font-size: 23px;
    }

    .section {
        padding: 100px 0;
        position: relative;
        padding: 100px 0;
        position: relative;
    }

    .bg-light {
        background-color: $background-color_1 !important;
    }

    .map {
        iframe {
            width: 100%;
            height: 400px;
        }
    }

    .mt-100 {
        margin-top: 100px;
    }

    .float-left {
        float: left !important;
        margin: 0 15px 0 0px;
    }

    .text-dark {
        color: $color_2 !important;
        color: $color_2 !important;
        color: $color_2 !important;
    }

    .text-muted {
        color: $color_3 !important;
        color: $color_3 !important;
        color: $color_5 !important;
        color: $color_1 !important;
    }

    .contact-icon {
        height: 74px;
        width: 74px;
        text-align: center;
        font-size: 34px;
        line-height: 74px;
        border-color: $border-color_1 !important;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
    }

    .shadow {
        -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.15) !important;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.15) !important;
    }

    .text-primary {
        color: $color_4 !important;
        background-color: $background-color_3;
    }

    .form-group {
        margin-bottom: 20px;

        label {
            font-size: 14px;
            font-weight: 700;
        }

        .form-control {
            -webkit-box-shadow: none;
            box-shadow: none;
            border: 1px solid #dee2e6;
            height: 42px;
            font-size: 13px;
            border-radius: 10px;
            -webkit-transition: all 0.5s ease;
            transition: all 0.5s ease;
        }
    }

    label {
        display: inline-block;
        margin-bottom: 0.5rem;
    }

    .form-control {
        display: block;
        width: 100%;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: $color_4;
        background-color: $background-color_2;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }

    .btn.btn-primary {
        background: #060f24;
        border: 1px solid #060f24;
    }

    .rounded {
        border-radius: 10px !important;
    }

    .contact-location-icon {
        border: 1px solid #060f24;
        position: absolute;
        width: 85px;
        height: 85px;
        line-height: 85px;
        font-size: 40px;
        top: 80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;

        i {
            -webkit-text-stroke: 2px #060f24;
            -webkit-text-fill-color: transparent;
        }
    }

  
    i.bx.bx-globe {
        color: #fff;
    }
    i.bx.bx-phone {
        color: #ffff;
       
    }

    i.bx.bxs-envelope {
        color: #fff;
     
    }
    i.bx.bxl-facebook {
        color: #060f24;
        font-size: 20px;
    }
    i.bx.bxl-twitter {
        color: #060f24;
        font-size: 20px;
    }
    i.bx.bxl-whatsapp {
        color: #060f24;
        font-size: 20px;
    }
    i.bx.bxl-pinterest-alt {
        color: #060f24;
        font-size: 20px;
    }
    i.bx.bxl-linkedin {
        color: #060f24;
        font-size: 20px; 
    }
    .contact-location {
        border: 1px solid #060f24;
        border-radius: 4px;
    }

    .list-inline-item {
        &:not(:last-child) {
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }

    .social-icon {
        li {
            a {
                border: 1px solid #060f24;
                height: 40px;
                text-align: center;
                font-size: 15px;
                width: 40px;
                line-height: 30px;
                -webkit-transition: all 0.4s ease;
                transition: all 0.4s ease;
                overflow: hidden;
                 display: flex;
                 justify-content: center;
                 align-items: center;
                border-radius: 50% !important;
               
            } :hover{
                background-color: #060f24 !important;
                color: #fff !important;
            } :hover i{
                color: #fff !important;
            }
        }
    }

    .border.rounded.text-center.p-4 {
        position: relative;
    }
}
@media (max-width: 768px)
 { 
    .contact-section .contact-icon {
        height: 50px !important;
        width: 50px !important;
        line-height: 0pX !important;
        display: flex !important;
        justify-content: center;
        align-items: center;
        i.bx.bx-globe {
            font-size: 25px;
        }
        i.bx.bx-phone {
            font-size: 25px;
        }
        i.bx.bxs-envelope {
            font-size: 25px;
        }
    }
    .contact-section
    .contact-item.mt-40 {
        width: 100%;
     .float-left {
        float: left !important;
        margin: 0 15px 0 0px;
        width: 20%;
        height: 100px;
    }
   
}
 }