$color_1: var(--font-white);
$color_2: var(--text-muted);
$color_3: var(--bg-hc-color);
$color_4: #42d182;
$color_5: #fe2053;
$color_6: #0c85e0;
$color_7: #041321;
$border-bottom-color_1: var(--white-color);
$border-bottom-color_2: var(--font-xlight);
$border-bottom-color_3: var(--secondary-color);

:root {
    --dark-color: #323A45;
    --heading-color: #222222;
    --font-color: #555555;
    --font-white: #ffffff;
    --font-light: #888888;
    --font-xlight: #f0f0f0;
    --font-ccc: #cccccc;
    --text-muted: #9A9A9A;
    --border-color: #e2e2e2;
    --card-color: #ffffff;
    --white-color: #ffffff;
    --body-color: #fcfcfc;
    --box-shadow: #c7c1c1;
    --box-shadow-light: #ececec;
    --font-hc-color: #888888;
    --bg-hc-color: #323A45;
}

[data-theme="dark"] {
    --dark-color: #ffffff;
    --heading-color: #c0c0c0;
    --font-color: #c7c7c7;
    --font-white: #ffffff;
    --font-light: #888888;
    --font-xlight: #333638;
    --font-ccc: #cccccc;
    --text-muted: #80858a;
    --border-color: #3b3f42;
    --card-color: #26282a;
    --white-color: #000000;
    --body-color: #222426;
    --box-shadow: #585858;
    --box-shadow-light: #323536;
    --font-hc-color: #888888;
    --bg-hc-color: #ffffff;
}

[data-theme="high-contrast"] {
    --dark-color: #ffffff;
    --heading-color: #ffffff;
    --font-color: #ffffff;
    --font-white: #ffffff;
    --font-light: #000000;
    --font-xlight: #ffffff;
    --font-ccc: #111111;
    --text-muted: #ffffff;
    --border-color: #ffffff;
    --card-color: #000000;
    --white-color: #000000;
    --body-color: #000000;
    --box-shadow: #333333;
    --box-shadow-light: #333333;
    --font-hc-color: #ffffff;
    --bg-hc-color: #000000;
}

[class="theme-purple"] {
    --primary-color: #7954AD;
    --primary-color2: #7B54B1;
    --primary-color3: #A37ADB;
    --secondary-color: #E76886;
    --secondary-color2: rgb(223, 126, 148);
    --link-color: #59C4BC;
    --primary-gradient: linear-gradient(45deg, #7B54B1, #E76886);
}


[class="theme-blue"] {
    --primary-color: #2929b9;
    --primary-color2: #4343D3;
    --primary-color3: #6C6CFF;
    --secondary-color: #F99006;
    --secondary-color2: #F92F47;
    --link-color: #87B70A;
    --primary-gradient: linear-gradient(45deg, #2929b9, #87B70A);
}

[class="theme-cyan"] {
    --primary-color: #59C4BC;
    --primary-color2: #33BAB0;
    --primary-color3: #8BCEC9;
    --secondary-color: #637AAE;
    --secondary-color2: #00ACEE;
    --link-color: #E76886;
    --primary-gradient: linear-gradient(45deg, #59C4BC, #637AAE);
}

[class="theme-green"] {
    --primary-color: #38C172;
    --primary-color2: #249D57;
    --primary-color3: #187741;
    --secondary-color: #0957C3;
    --secondary-color2: #5589D2;
    --link-color: #FED500;
    --primary-gradient: linear-gradient(45deg, #38C172, #249D57);
}

[class="theme-orange"] {
    --primary-color: #FFA901;
    --primary-color2: #FF7F00;
    --primary-color3: #FBC200;
    --secondary-color: #600489;
    --secondary-color2: #9439BC;
    --link-color: #38C172;
    --primary-gradient: linear-gradient(45deg, #FFA901, #600489);
}

[class="theme-blush"] {
    --primary-color: #FF6A4A;
    --primary-color2: #F5856D;
    --primary-color3: #FBC200;
    --secondary-color: #87B70A;
    --secondary-color2: #AAD736;
    --link-color: #056CDB;
    --primary-gradient: linear-gradient(45deg, #FF6A4A, #AAD736);
}

[class="theme-red"] {
    --primary-color: #F40000;
    --primary-color2: #F92C2C;
    --primary-color3: #F14F4F;
    --secondary-color: #030886;
    --secondary-color2: #252BCF;
    --link-color: #C7E203;
    --primary-gradient: linear-gradient(45deg, #F40000, #252BCF);
}

.chat-app {
    .people-list {
        width: 300px;
        position: absolute;
        left: 0px;
        top: -5px;
        z-index: 7;
    }

    .chat {
        margin-left: 280px;
        margin-top: -20px;
        border-left: 1px solid var(--border-color);
        min-height: 80vh;
        height: 610px;
        overflow-y: scroll;
    }
}

.people-list {
    -moz-transition: .5s;
    -o-transition: .5s;
    -webkit-transition: .5s;
    transition: .5s;

    .chat-list {
        li {
            padding: 10px 15px;
            list-style: none;
            border-radius: 3px;
            margin-bottom: 5px;

            &:hover {
                background: #061322 !important;
                color: $color_1;
                cursor: pointer;
            }

            .name {
                font-size: 15px;
            }
        }

        li.active {
            // background: var(--primary-color);
            background: #061322 !important;
            color: $color_1;
            cursor: pointer;
        }

        img {
            width: 45px;
            border-radius: 50%;
        }
    }

    img {
        float: left;
        border-radius: 50%;
    }

    .about {
        float: left;
        padding-left: 8px;
    }

    .status {
        opacity: .7;
        font-size: 13px;
    }
}

.chat {
    .chat-header {
        padding: 15px 20px;
        border-bottom: 1px solid var(--border-color);
        position: sticky;
        top: 0;
        background: white;
        z-index: 3;

        img {
            float: left;
            border-radius: 40px;
            width: 40px;
        }

        .chat-about {
            float: left;
            padding-left: 10px;
        }
    }

    .chat-history {
        padding: 20px;
        border-bottom: 2px solid var(--card-color);
        position: relative;
        min-height: 100vh;

        ul {
            padding: 0;

            li {
                list-style: none;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 50px;
                    overflow-y: scroll;
                }
            }
        }

        .message-data {
            margin-bottom: 15px;

            img {
                border-radius: 40px;
                width: 40px;
            }
        }

        .message-data-time {
            color: $color_2;
            padding-left: 6px;
            font-size: 12px;
        }

        .message {
            color: $color_3;
            padding: 18px 20px;
            line-height: 26px;
            font-size: 16px;
            border-radius: 7px;
            display: inline-block;
            position: relative;

            &:after {
                bottom: 100%;
                // left: 7%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: $border-bottom-color_1;
                border-width: 10px;
                // margin-left: -10px;
                right: 10px;
            }
        }

        .my-message {
            background: var(--font-xlight);

            &:after {
                bottom: 100%;
                left: 30px;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: $border-bottom-color_2;
                border-width: 10px;
                margin-left: -10px;
            }
        }

        .other-message {
            background: #c3c3c3;
            color: $color_7;
            text-align: right;

            &:after {
                border-bottom-color: #c3c3c3;
                bottom: 100%;
                left: 99%;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-width: 10px;
                margin-left: -34px;
            }
        }
    }

    .chat-message {
        padding-bottom: 5px;
        padding-top: 5px;
        padding-left: 20px;
        // position: fixed;
        position: sticky;
        // bottom: 35px;
        bottom: 0;
        width: 100%;
        z-index: 0;
        background-color: #fff;

    }
}

.online {
    margin-right: 2px;
    font-size: 8px;
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle;
    color: $color_5;
}

.me {
    margin-right: 2px;
    font-size: 8px;
    vertical-align: middle;
    color: $color_6;
}

.clearfix {
    &:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
}

@media only screen and (max-width: 992px) {
    .chat {
        margin-left: 280px;
        margin-top: -20px;
        border-left: 1px solid var(--border-color);
        min-height: 80vh !important;
        height: 0px !important;
        overflow-y: scroll;
    }


}

@media only screen and (max-width: 767px) {
    .chat-app {
        .people-list {
            height: 465px;
            width: 100%;
            overflow-x: auto;
            background: var(--white-color);
            left: -400px;
            // display: none;
            position: inherit !important;
        }

        .people-list.open {
            left: 0;
        }

        .chat {
            margin: 0;

            .chat-header {
                border-radius: 0.55rem 0.55rem 0 0;
            }
        }

        .chat-history {
            height: 300px;
            overflow-x: auto;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .chat-app {
        .chat-list {
            height: 650px;
            overflow-x: auto;
        }

        .chat-history {
            height: 600px;
            overflow-x: auto;
        }
    }
 
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .chat-app {
        .chat-list {
            height: 480px;
            overflow-x: auto;
        }

        .chat-history {
            height: calc(100vh - 350px);
            overflow-x: auto;
        }
    }
}

.gallery-main {
    width: 200px;
    margin-bottom: 20px;
    border: 2px solid #f0f0f0;

    .gallery-img {
        height: 200px;
        width: 200px;
        padding: 2px;

        img {
            width: 100%;
            max-width: 200px;
            height: 200px;
        }

    }
}

i.fa.fa-check.ms-3 {
    color: #7adfa7;
    font-size: 15px;
}

// .message-main {
//     background: #f0f0f0;
//     width: 50%;
//     padding: 0 7px 0 0px;
//     border-radius: 20px;
// }
// .chat .chat-history .my-message {
//     background: #f0f0f0;
//     // width: 95%;
// }
// .gallery-fff {
//     width: 5%;

// }
i.fa-solid.fa-check-double {
    color: #7cdfa9;
}

span.spend-main {
    padding: 5px 10px;
}

img.gallery-upload {
    width: 100%;
    max-width: 40px;
}

input.form-control.gallery-form {
    position: relative;
}

.spend-main {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border-right: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    display: flex;
    align-items: center;
}

img.send {
    width: 100%;
    max-width: 39px;
    height: 20px;


}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #fff;

}

.cardNew {
    // height: 100%;
    // min-height: 100vh !important;
    overflow: hidden;
    // z-index: 9;
  
}

::-webkit-scrollbar {
    display: none;
}

.fixedScroll {
    height: 750px;
    overflow-y: scroll;
    padding-left: 20px;
    padding-right: 20px;
}

.searchHeadereBar {
    padding: 24px;
    border-bottom: 1px solid #d9d9d9;
}

.gallery-img img {
    height: 200px;
    object-fit: contain;
}

aside.EmojiPickerReact.epr-main {
    border: none !important;
}



// search



.search {
    position: relative;
    margin: 0 auto;
}

.search input {
    width: 100%;
    height: 38px;
    padding: 0 12px 0 26px;
    background: white url(https://cssdeck.com/uploads/media/items/5/5JuDgOa.png) 8px 12px no-repeat;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px !important;
    border-color: #dedede;
    box-shadow: inset 0 1px #e5e7ed, 0 1px 0 #fcfcfc;
}

.search input:focus {
    outline: none;
    border-color: #66b1ee;
    -webkit-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -moz-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -ms-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    -o-box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
    box-shadow: 0 0 2px rgba(85, 168, 236, 0.9);
}

// .search input:focus + .results { display: block }
.showResults {
    display: block !important;
}
.showResults table tbody tr p{
    cursor: pointer !important;
} 
.search .results {
    display: none;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0;
    margin: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #cbcfe2 #c8cee7 #c4c7d7;
    border-radius: 3px;
    background-color: #fdfdfd;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fdfdfd), color-stop(100%, #eceef4));
    background-image: -webkit-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -moz-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -ms-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: -o-linear-gradient(top, #fdfdfd, #eceef4);
    background-image: linear-gradient(top, #fdfdfd, #eceef4);
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.search .results li { display: block }

.search .results li:first-child { margin-top: -1px }

.search .results li:first-child:before, .search .results li:first-child:after {
    display: block;
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: 50%;
    margin-left: -5px;
    border: 5px outset transparent;
}

.search .results li:first-child:before {
    border-bottom: 5px solid #c4c7d7;
    top: -11px;
}

.search .results li:first-child:after {
    border-bottom: 5px solid #fdfdfd;
    top: -10px;
}

.search .results li:first-child:hover:before, .search .results li:first-child:hover:after { display: none }

.search .results li:last-child { margin-bottom: -1px }

.search .results table {
    display: block;
    position: relative;
    margin: 0 -1px;
    padding: 6px 40px 6px 10px;
    color: #808394;
    font-weight: 500;
    text-shadow: 0 1px #fff;
    border-bottom: 1px solid rgb(136, 136, 136);
    border-radius: 3px;
}
.search .results table p { font-weight: 200 }

.search .results a:before {
    content: '';
    width: 18px;
    height: 18px;
    position: absolute;
    top: 50%;
    right: 10px;
    margin-top: -9px;
    background: url("https://cssdeck.com/uploads/media/items/7/7BNkBjd.png") 0 0 no-repeat;
}

:-moz-placeholder {
    color: #a7aabc;
    font-weight: 200;
}

::-webkit-input-placeholder {
    color: #a7aabc;
    font-weight: 200;
}

.lt-ie9 .search input { line-height: 26px }

.online {
    margin-right: 2px;
    font-size: 8px;
    margin-right: 2px;
    font-size: 13px;
    vertical-align: middle;
    color: #3efe20;
}